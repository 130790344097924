import { Component, Input } from '@angular/core';
import { Usuario } from '../../models';
import { FirestoreService } from 'src/app/services/firestore.service';
import { FirebaseauthService } from 'src/app/services/firebaseauth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() admin: boolean;
  @Input() user: boolean;

  @Input() rider: Usuario;
  @Input() shop: Usuario;
  usuario: Usuario = {
    uid: '',
    nombre: '',
    responsable: '',
    apellido: '',
    email: '',
    password: '',
    referencia: '',
    foto: '',
    foto1: '',
    foto2: '',
    latitud: '',
    longitud: '',
    hash: '',
    qr: '',
    categoria: '',
    tipo: '',
    telefono: '',
    pageWeb: '',
    direccion: '',
    horario: '',
    suscripcion: '',
    codPostal: '',
    ciudad: '',
    description: '',
  };
  


  constructor(  public firestoreService: FirestoreService, private firebaseauthService: FirebaseauthService,) {
    this.getUserUid()
  }

   ngOnInit() {}

   getUserUid() {
    this.firebaseauthService.stateAuth().subscribe( res => {
      if (res !== null) {
         
          this.user = true;
      } else {
        this.user = false;
      }
    
    });
  }
}

