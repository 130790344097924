import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetAdminComponent } from './set-admin/set-admin.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ComponentesModule } from '../componentes/componentes.module';
import { PipesModule } from '../pipes/pipes.module';
import { GenerarReporteComponent } from './componentes/generar-reporte/generar-reporte.component';
import { AdminComponent } from './admin/admin.component';





@NgModule({
  declarations: [
    SetAdminComponent,
    AdminComponent,
    GenerarReporteComponent
  ],

  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    RouterModule,
    ComponentesModule,
    PipesModule, 
  ],


})
export class BackendModule { }
