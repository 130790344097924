import { Component, OnInit } from '@angular/core';
import { Favorito } from 'src/app/models';
import { Usuario } from '../../models';
import { ToastController, LoadingController } from '@ionic/angular';
import { Geolocation} from '@ionic-native/geolocation/ngx';
import { FirestoreService } from '../../services/firestore.service';

@Component({
  selector: 'app-star',
  templateUrl: './star.component.html',
  styleUrls: ['./star.component.scss'],
})
export class StarComponent implements OnInit {

  user = false;
  admin = false;
  favorites = true;
  favorito: Favorito;
  usuarios: Usuario[] = [];
  comerciosProductosLoad: Usuario[] = [];
  comerciosServiciosLoad: Usuario[] = [];
  coordenadas: any;
  loading: HTMLIonLoadingElement;
  lat: number;
  lng: number; 
  private path = 'Usuario/';


  constructor(public toastController: ToastController,
              public loadingController: LoadingController,
              public geolocation: Geolocation,
              public firestoreService: FirestoreService) { 
                  this.loadComerciosProductos();
                  this.loadComerciosServicios();
              }

  ngOnInit() {}

  calcularMasCercano(comercios: Usuario[]): Promise<void> {
    return new Promise( async (resolve, reject) => {
        console.log('calcular mas cercano');
        if (!this.coordenadas) {
          this.coordenadas = await this.geolocation.getCurrentPosition();
        }
        if (this.coordenadas) {
            this.lat = this.coordenadas.coords.latitude;
            this.lng = this.coordenadas.coords.longitude;  
            comercios.forEach ( cliente => {
                    const latTienda = cliente.latitud;
                    const longTienda = cliente.longitud;
                    const R = 6371; // km
        
                      let dLat = (latTienda-this.lat) as any;
                      dLat = this.toRad(dLat)
        
                          let dLon = (longTienda-this.lng) as any;
                          dLon = this.toRad(dLon);
        
                          const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        
                                  Math.cos(this.toRad(this.lat)) * Math.cos(this.toRad(latTienda)) *
        
                                  Math.sin(dLon/2) * Math.sin(dLon/2);
        
                          const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        
                          const de = R * c;
                          console.log('de ->', de);
                                  cliente.distancia = de;
            });
            comercios.sort( (n1, n2) => {
                if (n1.distancia > n2.distancia ) { return 1}
                if (n1.distancia <=  n2.distancia ) { return -1}
                return 0;
            });
        } else {
          console.log('no se puede obtener ubicación');
          this.presentToast('No podemos obtener tu ubicación');
        }
        resolve();
    });
  }

  toRad(grados: number) {
          return grados * Math.PI/180
  }

  loadComerciosProductos() { 
      this.firestoreService.getClient<Usuario>(this.path).subscribe( async res =>{ 
          if (this.comerciosProductosLoad.length) { return; }
          const destacados: Usuario[] = [];
          await this.presentLoading('ordenando por más cercanos...');
          res.forEach( comercio => {
             if (comercio.destacado) {
                destacados.push(comercio);
             } 
          });
          await this.calcularMasCercano(destacados);
          this.comerciosProductosLoad = [];
          this.comerciosProductosLoad.push(...destacados);
          this.usuarios.push(...this.comerciosProductosLoad);   
          this.dismissLoading();
      }); 

  }

  loadComerciosServicios() { 
        console.log('loadComerciosServicios() -> ', this.comerciosServiciosLoad); 
        this.firestoreService.getServices<Usuario>(this.path).subscribe( async (res) => { 
              if (this.comerciosServiciosLoad.length) { return; }
              const destacados: Usuario[] = [];
              await this.presentLoading('ordenando por más cercanos...');
              res.forEach( comercio => {
                if (comercio.destacado) {
                    destacados.push(comercio);
                } 
              });
              await this.calcularMasCercano(destacados);
              this.comerciosServiciosLoad = []; 
              this.comerciosServiciosLoad.push(...destacados);
              this.usuarios.push(...this.comerciosServiciosLoad);  
              this.dismissLoading();
        }); 

  }

  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      color: 'light',
    });
    toast.present();
  }

  async presentLoading(texto: string) {
    console.log('presentLoading');
    this.loading = await this.loadingController.create({
      message: texto,
      duration: 2000
    });
    await this.loading.present();
    return;
  }

  async dismissLoading() {
      if (this.loading) {
        console.log('dismissLoading()');
        await this.loading.dismiss();
      }
  }

}
