import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MenuItem, Usuario } from '../../models';
import { FirestoreService } from '../../services/firestore.service';
import { FirestorageService } from '../../services/firestorage.service';
import { AlertController } from '@ionic/angular';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-product-management',
  templateUrl: './product-management.component.html',
  styleUrls: ['./product-management.component.scss']
})
export class ProductManagementComponent implements OnInit {
  @Input() categories: string[] = [];
  @Input() usuario: Usuario;
  @Input() uid: string;
  @Output() categoryAdded = new EventEmitter<void>();
  @Output() productAdded = new EventEmitter<void>();

  categoryForm: FormGroup;
  productForm: FormGroup;
  isMultiplePrice = false;
  editingProductId: string | null = null;

  constructor(
    private fb: FormBuilder,
    private firestoreService: FirestoreService,
    private firestorageService: FirestorageService,
    private alertController: AlertController
  ) {
    this.categoryForm = this.fb.group({
      category: ['', Validators.required]
    });

    this.productForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      category: ['', Validators.required],
      isMultiplePrice: [false],
      price: [0, [Validators.required, Validators.min(0)]],
      priceGroups: this.fb.array([]),
      available: [true],
      ingredients: [''],
      calories: [0],
      servingSize: [''],
      tags: [''],
      image: ['']
    });
  }

  ngOnInit() {}

  async onSubmitCategory() {
    const category = this.categoryForm.value.category.trim().toLowerCase();
    if (this.categories.map(cat => cat.toLowerCase()).includes(category)) {
      const alert = await this.alertController.create({
        header: 'Error',
        message: 'La categoría ya existe.',
        buttons: ['OK']
      });
      await alert.present();
      return;
    }
    const newCategory = category.charAt(0).toUpperCase() + category.slice(1);
    this.categories.push(newCategory);
    this.categories.sort();
    this.firestoreService.createDoc({ name: newCategory }, `Usuario/${this.uid}/categories`, newCategory).then(() => {
      console.log('Categoría subida exitosamente');
      this.categoryForm.reset();
      this.categoryAdded.emit();
    }).catch((error) => {
      console.error('Error al subir la categoría: ', error);
    });
  }

  togglePriceType() {
    this.isMultiplePrice = !this.isMultiplePrice;
    const priceGroups = this.productForm.get('priceGroups') as FormArray;
    if (this.isMultiplePrice) {
      priceGroups.push(this.createPriceGroup());
    } else {
      while (priceGroups.length) {
        priceGroups.removeAt(0);
      }
    }
  }

  createPriceGroup(): FormGroup {
    return this.fb.group({
      size: '',
      amount: 0
    });
  }

  addPriceGroup() {
    const priceGroups = this.productForm.get('priceGroups') as FormArray;
    priceGroups.push(this.createPriceGroup());
  }

  removePriceGroup(index: number) {
    const priceGroups = this.productForm.get('priceGroups') as FormArray;
    priceGroups.removeAt(index);
  }

  async onSubmitProduct() {
    if (this.productForm.valid) {
      const { name, description, category, price, available, ingredients, calories, servingSize, tags } = this.productForm.value;
      const prices = this.productForm.value.priceGroups.map(group => ({ size: group.size, amount: group.amount }));
      const filePath = this.productForm.value.image ? `menuItems/${Date.now()}_${this.productForm.value.image.name}` : '';
      const fileRef = this.productForm.value.image ? this.firestorageService.ref(filePath) : null;
      const uploadTask = this.productForm.value.image ? this.firestorageService.upload(filePath, this.productForm.value.image) : null;

      if (uploadTask) {
        uploadTask.snapshotChanges().pipe(
          finalize(() => {
            fileRef.getDownloadURL().subscribe((url) => {
              const newItem: MenuItem = {
                name,
                description,
                category,
                prices,
                available,
                ingredients: ingredients ? ingredients.split(',').map((item: string) => item.trim()) : [],
                calories,
                servingSize,
                tags: tags ? tags.split(',').map((item: string) => item.trim()) : [],
                image: url,
                uid: this.uid,
                id: this.editingProductId ? this.editingProductId : this.firestoreService.getId() // Set the ID here
              };
              if (this.editingProductId) {
                this.updateMenuItem(newItem);
              } else {
                this.createMenuItem(newItem);
              }
            });
          })
        ).subscribe();
      } else {
        const newItem: MenuItem = {
          name,
          description,
          category,
          prices,
          available,
          ingredients: ingredients ? ingredients.split(',').map((item: string) => item.trim()) : [],
          calories,
          servingSize,
          tags: tags ? tags.split(',').map((item: string) => item.trim()) : [],
          image: '',
          uid: this.uid,
          id: this.editingProductId ? this.editingProductId : this.firestoreService.getId() // Set the ID here
        };
        if (this.editingProductId) {
          this.updateMenuItem(newItem);
        } else {
          this.createMenuItem(newItem);
        }
      }
    } else {
      console.error('Formulario no es válido');
    }
  }

  createMenuItem(newItem: MenuItem) {
    const path = `Usuario/${this.uid}/menuItems`;
    this.firestoreService.createDoc(newItem, path, newItem.id).then(() => {
      console.log('Producto subido exitosamente');
      this.productForm.reset();
      this.editingProductId = null;
      this.productAdded.emit();
    }).catch((error) => {
      console.error('Error al subir el producto: ', error);
    });
  }

  updateMenuItem(updatedItem: MenuItem) {
    const path = `Usuario/${this.uid}/menuItems`;
    this.firestoreService.updateDoc(updatedItem, path, updatedItem.id).then(() => {
      console.log('Producto actualizado exitosamente');
      this.productForm.reset();
      this.editingProductId = null;
      this.productAdded.emit();
    }).catch((error) => {
      console.error('Error al actualizar el producto: ', error);
    });
  }

  get priceGroups(): FormArray {
    return this.productForm.get('priceGroups') as FormArray;
  }
}
