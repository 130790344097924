import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { Cliente, Usuario } from '../../models';
import { FirestoreService } from '../../services/firestore.service';
import { FirebaseauthService } from '../../services/firebaseauth.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { FirestorageService } from '../../services/firestorage.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-qr',
  templateUrl: './qr.component.html',
  styleUrls: ['./qr.component.scss'],
})
export class QrComponent implements OnInit {

  usuario: Usuario = {
    uid: '',
    nombre: '',
    responsable: '',
    apellido: '',
    email: '',
    password: '',
    referencia: '',
    foto: '',
    foto1: '',
    qr:'',

    foto2: '',
    latitud: '',
    longitud: '',
    hash: '',
    categoria: '',
    tipo:'',

    telefono: '',
    pageWeb: '',
  direccion: '',
  horario: '',
  suscripcion: '',
  codPostal: '',
  ciudad: '',
  description: '',

  };

  user = false;
  suscriber: Subscription;
  suscriberUserInfo: Subscription;
  newFile: [];
  newFile1: [];



  constructor(public modalController: ModalController, public firestoreService: FirestoreService, private firebaseauthService: FirebaseauthService, public geolocation: Geolocation, public firestorageService: FirestorageService, public alertController: AlertController) {this.getUserUid()}

  ngOnInit() {}

  ngOnDestroy(){
    console.log('ngOnDestry() modal')
    if(this.suscriber){
      this.suscriber.unsubscribe();
        }
  }

  getUserUid() {
    this.firebaseauthService.stateAuth().subscribe( res => {
      if (res !== null) {
         
          this.user = true;
      } else {
        this.user = false;
      }
    
    });
  }

  async guardarUser(){
    const path = 'Usuario';
    const name = this.usuario.nombre;  
    this.firestoreService.createDoc(this.usuario, path, this.usuario.uid).then( res=> {
    console.log('guardado con exito')
    
  });  
  }

  async newQrUpload(event: any){

    if (event.target.files.length > 0) {
      this.newFile1 = event.target.files[1]; 
      const reader1 = new FileReader();
      reader1.onload = ((image1) => {
        this.usuario.qr = image1.target.result as string;
      });
      reader1.readAsDataURL(event.target.files[1]);
    } 
  }

}
