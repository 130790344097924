import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore.service';
import { Cliente } from '../../models';
import { FirebaseauthService } from '../../services/firebaseauth.service';
import { Geolocation, Geoposition } from '@ionic-native/geolocation/ngx';

@Component({
  selector: 'app-destak',
  templateUrl: './destak.component.html',
  styleUrls: ['./destak.component.scss'],
})
export class DestakComponent implements OnInit {  

  latitud: number;
  longitud: number;


  admin = false;
  user = false;

  private path = 'Clientes/';

  clientes: Cliente[] = [];

  constructor(public firestoreService: FirestoreService, private firebaseauthService: FirebaseauthService, public geolocation: Geolocation) {
    //this.geolocal();  
    this.loadClientes();

   }

  ngOnInit() {
    
  }


  
  loadClientes() { 

    this.firestoreService.getCollection<Cliente>(this.path).subscribe( res =>{ 
      if (this.latitud !== null) {
        this.clientes = res;
        this.getUid();
        this.getUserUid();
      }else{
        console.log('no hay coordenadas');
      }      

      
    }); 
    }
    
  

  getUid() {
    this.firebaseauthService.stateAuth().subscribe( res => {
      if (res !== null) {
        if (res.uid === 'aKcLIeSZNVb8XbYSxuZxUQWtzdH3') { 
          this.admin = true;
      } else {
        this.admin = false;
      }
    } else {
      this.admin = false;
    }
    });
  }

  getUserUid() {
    this.firebaseauthService.stateAuth().subscribe( res => {
      if (res !== null) {
         
          this.user = true;
      } else {
        this.user = false;
      }
    
    });
  }

  /* async geolocal(){
    const obtenerCoordenadas = await this.geolocation.getCurrentPosition();
    this.latitud = obtenerCoordenadas.coords.latitude;
    this.longitud = obtenerCoordenadas.coords.longitude;
      console.log(this.latitud, this.longitud)
    }; */



  }



  




