import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore.service';
import { Usuario } from '../../models';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { ToastController, LoadingController } from '@ionic/angular';

@Component({
  selector: 'app-home-web',
  templateUrl: './home-web.component.html',
  styleUrls: ['./home-web.component.scss'],
})
export class HomeWebComponent implements OnInit {
  lat: number | null;
  lng: number | null;
  usuarios: Usuario[] = [];
  comerciosProductosLoad: Usuario[] = [];
  loading: HTMLIonLoadingElement;
  coordenadas: any;
  pageSize = 10;  // Número de items a cargar por página
  lastVisible: any = null;
  isLoading = false;

  private readonly path = 'Usuario/';

  constructor(
    private firestoreService: FirestoreService,
    private geolocation: Geolocation,
    private toastController: ToastController,
    private loadingController: LoadingController
  ) {}

  async ngOnInit() {
    console.log('ngOnInit triggered');
    const latString = localStorage.getItem('lat');
    const lngString = localStorage.getItem('lng');
    this.lat = latString !== 'null' ? parseFloat(latString) : null;
    this.lng = lngString !== 'null' ? parseFloat(lngString) : null;
    
    if (this.lat !== null && this.lng !== null) {
      this.loadComerciosProductos();
    } else {
      this.loadComerciosProductosSinUbicacion();
    }
  }

  private async calcularMasCercano(comercios: Usuario[]): Promise<void> {
    try {
      if (this.lat && this.lng) {
        comercios.forEach(comercio => {
          comercio.distancia = this.calculateDistance(comercio.latitud, comercio.longitud);
        });
        comercios.sort((n1, n2) => n1.distancia - n2.distancia);
      } else {
        console.log('No se puede obtener ubicación. Filtrando por relevancia.');
        await this.filtrarPorComentarios(comercios);
      }
    } catch (error) {
      this.presentToast('No podemos obtener tu ubicación');
      console.error('Error calculating nearest stores:', error);
    }
  }

  private async filtrarPorComentarios(comercios: Usuario[]): Promise<void> {
    try {
      for (let comercio of comercios) {
        const count = await this.firestoreService.getUserCommentsCount(comercio.uid);
        comercio.numComentarios = count;
      }
      comercios.sort((n1, n2) => (n2.numComentarios || 0) - (n1.numComentarios || 0));
    } catch (error) {
      console.error('Error fetching comment counts:', error);
    }
  }

  private calculateDistance(latTienda: number, longTienda: number): number {
    const R = 6371; // km
    const dLat = this.toRad(latTienda - this.lat);
    const dLon = this.toRad(longTienda - this.lng);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.toRad(this.lat)) * Math.cos(this.toRad(latTienda)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  }

  private toRad(grados: number): number {
    return grados * Math.PI / 180;
  }

  private async loadComerciosProductos(event?: any) {
    if (this.isLoading) return;
    this.isLoading = true;
    console.log('loadComerciosProductos() called');

    this.firestoreService.getPaginatedClients<Usuario>(this.path, this.pageSize, this.lastVisible).subscribe(async res => {
      console.log('Datos obtenidos de Firestore:', res);
      if (res.length === 0) {
        if (event && event.target) {
          event.target.disabled = true;
        }
        this.isLoading = false;
        return;
      }

      await this.presentLoading('ordenando por más cercanos...');
      await this.calcularMasCercano(res);
      console.log('comercios -> ', res);

      // Eliminar duplicados
      res.forEach(comercio => {
        if (!this.comerciosProductosLoad.some(c => c.uid === comercio.uid)) {
          this.comerciosProductosLoad.push(comercio);
        }
      });

      this.usuarios = [...this.comerciosProductosLoad];
      this.dismissLoading();

      if (res.length < this.pageSize) {
        if (event && event.target) {
          event.target.disabled = true;
        }
      } else {
        this.lastVisible = res[res.length - 1];
      }

      if (event && event.target) {
        event.target.complete();
      }
      this.isLoading = false;
    }, error => {
      this.presentToast('Error al obtener datos de Firestore');
      console.error('Error fetching Firestore data:', error);
      if (event && event.target) {
        event.target.complete();
      }
      this.isLoading = false;
    });
  }

  private async loadComerciosProductosSinUbicacion(event?: any) {
    if (this.isLoading) return;
    this.isLoading = true;
    console.log('loadComerciosProductosSinUbicacion() called');

    this.firestoreService.getPaginatedClients<Usuario>(this.path, this.pageSize, this.lastVisible).subscribe(async res => {
      console.log('Datos obtenidos de Firestore:', res);
      if (res.length === 0) {
        if (event && event.target) {
          event.target.disabled = true;
        }
        this.isLoading = false;
        return;
      }

      await this.presentLoading('ordenando por relevancia...');
      await this.filtrarPorComentarios(res);
      console.log('comercios -> ', res);

      // Eliminar duplicados
      res.forEach(comercio => {
        if (!this.comerciosProductosLoad.some(c => c.uid === comercio.uid)) {
          this.comerciosProductosLoad.push(comercio);
        }
      });

      this.usuarios = [...this.comerciosProductosLoad];
      this.dismissLoading();

      if (res.length < this.pageSize) {
        if (event && event.target) {
          event.target.disabled = true;
        }
      } else {
        this.lastVisible = res[res.length - 1];
      }

      if (event && event.target) {
        event.target.complete();
      }
      this.isLoading = false;
    }, error => {
      this.presentToast('Error al obtener datos de Firestore');
      console.error('Error fetching Firestore data:', error);
      if (event && event.target) {
        event.target.complete();
      }
      this.isLoading = false;
    });
  }

  private async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      color: 'light',
    });
    toast.present();
  }

  private async presentLoading(texto: string) {
    console.log('presentLoading');
    this.loading = await this.loadingController.create({
      message: texto,
      duration: 2000
    });
    await this.loading.present();
  }

  private async dismissLoading() {
    if (this.loading) {
      console.log('dismissLoading()');
      await this.loading.dismiss();
    }
  }

  private async geolocal() {
    try {
      const obtenerCoordenadas = await this.geolocation.getCurrentPosition();
      this.lat = obtenerCoordenadas.coords.latitude;
      this.lng = obtenerCoordenadas.coords.longitude;
      console.log('Ubicación obtenida:', this.lat, this.lng);
    } catch (error) {
      this.presentToast('Error al obtener la ubicación');
      console.error('Error fetching location:', error);
    }
  }

  loadData(event) {
    this.loadComerciosProductos(event);
  }
}
