import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore.service';
import { Order, Usuario } from '../../models';
import { FirebaseauthService } from '../../services/firebaseauth.service';
import { ModalController } from '@ionic/angular';
import { OrderDetailComponent } from '../order-detail/order-detail.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
  orders: Order[] = [];
  filteredOrders: Order[] = [];
  uid: string;
  suscriberUserInfo: Subscription;

  constructor(
    private firestoreService: FirestoreService,
    private firebaseauthService: FirebaseauthService,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.firebaseauthService.stateAuth().subscribe(async (user) => {
      if (user) {
        this.uid = user.uid;
/*         this.loadOrders(); */
      } else {
        console.error("No se pudo obtener el UID del usuario");
      }
    });
  }

 /*  loadOrders() {
    this.firestoreService.getOrdersExcludingFavorites('orders').subscribe((orders) => {
      this.orders = orders;
      this.filteredOrders = orders; // Aquí puedes aplicar filtros adicionales si lo necesitas
    });
  } */

  async openOrderDetail(order: Order) {
    const modal = await this.modalController.create({
      component: OrderDetailComponent,
      componentProps: { order }
    });

    modal.onDidDismiss().then(() => {
      this.removeOrder(order);
    });

    return await modal.present();
  }

  removeOrder(order: Order) {
    this.filteredOrders = this.filteredOrders.filter(o => o !== order);
  }
}
