import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FirestoreService } from '../../services/firestore.service';

@Component({
  selector: 'app-schedule-modal',
  templateUrl: './schedule-modal.component.html',
  styleUrls: ['./schedule-modal.component.scss'],
})
export class ScheduleModalComponent implements OnInit {
  @Input() schedule: any;
  @Input() uid: string;
  weekDays: any[];
  timeZone: string;

  constructor(private modalController: ModalController, private firestoreService: FirestoreService) {}

  ngOnInit() {
    this.weekDays = this.schedule.weekDays;
    this.timeZone = this.schedule.timeZone || 'Europe/Madrid'; // Default timeZone
  }

  addHours(day: any) {
    day.hours.push({ open: '', close: '' });
  }

  removeHours(day: any, index: number) {
    day.hours.splice(index, 1);
  }

  async saveSchedule() {
    const path = `Usuario/${this.uid}/horarioSemanal/schedule`;
    await this.firestoreService.setDocument({ weekDays: this.weekDays, timeZone: this.timeZone }, path);
    this.closeModal();
  }

  async deleteSchedule() {
    await this.firestoreService.deleteSchedule(this.uid);
    this.closeModal();
  }

  async closeModal() {
    const topModal = await this.modalController.getTop();
    if (topModal) {
      console.log('Closing top modal...');
      await topModal.dismiss();
    }
  }
}
