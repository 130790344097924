import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Usuario } from '../../models';
import { FirebaseauthService } from '../../services/firebaseauth.service';
import { FirestorageService } from '../../services/firestorage.service';
import { FirestoreService } from '../../services/firestore.service';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { Router } from '@angular/router';
import { Geolocation, Geoposition } from '@ionic-native/geolocation/ngx';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { Location } from '@angular/common';




@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

 


usuario: Usuario = {
  uid: '',
  nombre: '',
  responsable: '',

  apellido: '',
  email: '',
  password: '',
  referencia: '',
  foto: '',
    foto1: '',
    foto2: '',
  latitud: '',
  longitud: '',
  hash: '',
  qr:'',

  categoria: '',
  tipo: '',

  telefono: '',
  pageWeb: '',
  direccion: '',
  horario: '',
  suscripcion: '',
  codPostal: '',
  ciudad: '',
  description: '',

};

newFile: any;


uid = '';

suscriberUserInfo: Subscription;

ingresarEnable = true;

  constructor(private location: Location, public toastController: ToastController, public alertController: AlertController, public firebaseauthService: FirebaseauthService, public firestorageService: FirestorageService, public firestoreService: FirestoreService, public auth: AngularFireAuth, public router: Router, public geolocation: Geolocation, public modalController: ModalController) {

      this.geolocal();
      this.firebaseauthService.stateAuth().subscribe( res => {
        console.log(res);
        if (res !== null){
          this.uid = res.uid
          this.getUserInfo(this.uid);
        }else{
        this.initUsuario();       
        }
      });

   }

  async ngOnInit() {
   
    const uid = await this.firebaseauthService.getUid();
    console.log(uid);

  }

  initUsuario() {
    this.uid = '';
    this.usuario = {
      uid: '',
      nombre: '',
      responsable: '',
      apellido: '',
      email: '',
      password: '',
      referencia: '',
      foto: '',
      foto1: '',
      foto2: '',
      latitud: '',
      longitud: '',
      qr:'',  
      hash: '',
      categoria: '',
      tipo: '',
      pageWeb: '',
      telefono: '',
      direccion: '',
      horario: '',
      suscripcion: '',
      codPostal: '',
      ciudad: '',
      description: '',
    };
}




  async registrarse(){
    const credenciales = {
      email: this.usuario.email,
      password: this.usuario.password,
    };
    const res = await this.firebaseauthService.registrar(credenciales.email, credenciales.password).catch(err => {
     console.log('erro -> ', err);
     });
     const uid = await this.firebaseauthService.getUid();     
     this.usuario.uid = uid;
     this.router.navigateByUrl("/home");
     this.guardarUser();
     this.sendEmail()
    console.log(uid);


    
  var user = firebase.auth().currentUser;

if (user != null) {
  user.providerData.forEach(function (profile) {
    console.log("Sign-in provider: " + profile.providerId);
    console.log("  Provider-specific UID: " + profile.uid);
    console.log("  Name: " + profile.displayName);
    console.log("  Email: " + profile.email);
    console.log("  Photo URL: " + profile.photoURL);
  });
}
  }

  async deleteUser(){
    const alert = await this.alertController.create({
      cssClass: 'body',
      header: '¿ Seguro que quieres eliminar su perfil?',
      //message: '¡No te pierdas los descuentos y poder añadir favoritos!',
      buttons: [

        {
          text: 'Si',
          handler: () => {
            this.deleted() 
          }
        },

        {
          text: 'No',
          role: 'cancel',
          cssClass: '',
          handler: (blah) => {
            console.log('Perfil no eliminado');
          }
        } 
      ]
      
    });
   
    await alert.present();
  }
  
  goBack() {
    this.location.back(); // Navega a la página anterior
  }

  

  async deleted(){
    const path = 'Usuario'
    const name = this.usuario.uid;
    const delet = await this.firestoreService.deleteDoc(path, this.usuario.uid).then( res=> {
      console.log('Usuario eliminado con exito');
      this.deleteAuth()
      this.salir();
      this.router.navigateByUrl("/home");
    });  
    }

    async deleteAuth(){
    const deleti = await this.firebaseauthService.deleteUser();
    }


async guardarUser(){
  const path = 'Usuario';
  const name = this.usuario.nombre;  
  this.firestoreService.createDoc(this.usuario, path, this.usuario.uid).then( res=> {
  console.log('registrado con exito')
  this.presentToast('guardado con exito');

});  
}

async sendEmail(){    
  const uid = await this.firebaseauthService.verificarEmail(); 
}


async salir(){
    this.firebaseauthService.logout();
    this.suscriberUserInfo.unsubscribe();
    this.router.navigateByUrl("/home");
}

async salirPerfil(){
  const alert = await this.alertController.create({
    cssClass: 'body',
    header: '¿ Seguro que quieres salir de Storing ?',
    message: '¡No te pierdas los descuentos y poder añadir favoritos!',
    buttons: [

      {
        text: 'Si',
        handler: () => {
          this.salir().then(() => {
            this.router.navigateByUrl("/home");
          
          }); 
        }
      },

      {
        text: 'No',
        role: 'cancel',
        cssClass: '',
        handler: (blah) => {
          console.log('Confirm Cancel: blah');
        }
      } 
    ]
    
  });
 
  await alert.present();
  
}

  getUserInfo(uid: string){
    const path = 'Usuario';
    this.suscriberUserInfo = this.firestoreService.getDoc<Usuario>(path, this.uid).subscribe( res => {
      this.usuario = res;
      
    })
  }

  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      color: 'light',
    });
    toast.present();
  }

  ingresar(){
    const credenciales = {
      email: this.usuario.email,
      password: this.usuario.password,
    };
    this.firebaseauthService.login(credenciales.email, credenciales.password).then( res => {
      console.log('ingresado con exito')
      this.router.navigateByUrl("/home");      
    })
  }

  async newImageUpload(event: any){

    if (event.target.files && event.target.files[0]) {
      this.newFile = event.target.files[0]; 
      const reader = new FileReader();
      reader.onload = ((image) => {
        this.usuario.foto = image.target.result as string;
      });
      reader.readAsDataURL(event.target.files[0]);
    } 
  }

  async geolocal(){
    const obtenerCoordenadas = await this.geolocation.getCurrentPosition();
    this.usuario.latitud = obtenerCoordenadas.coords.latitude;
    this.usuario.longitud = obtenerCoordenadas.coords.longitude;
      console.log(this.usuario.latitud, this.usuario.longitud)
    };

    
  }