import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Usuario, MenuItem, Order } from '../../models';
import { FirebaseauthService } from '../../services/firebaseauth.service';
import { FirestoreService } from '../../services/firestore.service';
import { AlertController, ModalController } from '@ionic/angular';
import { ScheduleModalComponent } from 'src/app/componentes/schedule-modal/schedule-modal.component';
import {  FormGroup } from '@angular/forms';
import { OrderFormModalComponent } from 'src/app/componentes/order-form-modal/order-form-modal.component';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss'],
})
export class ShopComponent implements OnInit, OnDestroy {
  admin = false;
  user = false;
  rider = false;
  shop = false;
  orderForm: FormGroup;
  suscriberUserInfo: Subscription;
  uid = '';
  scheduleSub: Subscription;

  usuario: Usuario = {
    uid: '',
    video: '',
    nombre: '',
    responsable: '',
    apellido: '',
    email: '',
    password: '',
    referencia: '',
    foto: '',
    foto1: '',
    qr: '',
    foto2: '',
    latitud: '',
    longitud: '',
    hash: '',
    categoria: '',
    tipo: '',
    telefono: '',
    pageWeb: '',
    direccion: '',
    horario: '',
    suscripcion: '',
    codPostal: '',
    ciudad: '',
    description: '',
    destacado: false,
  };

  categories: string[] = [];
  allProducts: MenuItem[] = [];
  editingProduct: MenuItem | null = null;

  constructor(
    public firebaseauthService: FirebaseauthService,
    public firestoreService: FirestoreService,
    public alertController: AlertController,
    private modalController: ModalController
  ) {
    this.firebaseauthService.stateAuth().subscribe((res) => {
      if (res !== null) {
        this.uid = res.uid;
        this.getUserInfo(this.uid);
        this.loadCategories();
        this.loadProducts();
      } else {
        console.log('no es cliente');
      }
    });
  }

  async ngOnInit() {

    const uid = await this.firebaseauthService.getUid();
    console.log(uid);
  }

  ngOnDestroy() {
    if (this.suscriberUserInfo) {
      this.suscriberUserInfo.unsubscribe();
    }
    if (this.scheduleSub) {
      this.scheduleSub.unsubscribe();
    }
  }

  getUserInfo(uid: string) {
    const path = 'Usuario';
    this.suscriberUserInfo = this.firestoreService
      .getDoc<Usuario>(path, uid)
      .subscribe((res) => {
        if (res) {
          this.usuario = res;
        }
      });
  }

  loadCategories() {
    this.firestoreService
      .getCollection(`Usuario/${this.uid}/categories`)
      .subscribe((res: any[]) => {
        this.categories = res.map((category) => category.name);
        console.log('Categorías cargadas:', this.categories);
      });
  }

  loadProducts() {
    const path = `Usuario/${this.uid}/menuItems`;
    this.firestoreService
      .getCollection<MenuItem>(path)
      .subscribe((res: MenuItem[]) => {
        this.allProducts = res;
        console.log('Productos cargados:', this.allProducts);
      });
  }

  editProduct(product: MenuItem) {
    this.editingProduct = product;
  }

  async openScheduleModal() {
    console.log('Fetching schedule...');
    if (this.scheduleSub) {
      this.scheduleSub.unsubscribe();
    }

    try {
      this.scheduleSub = this.firestoreService.getSchedule(this.uid).subscribe(
        async (schedule) => {
          console.log('Schedule fetched:', schedule);

          // Cierra cualquier modal existente antes de abrir uno nuevo
          const existingModal = await this.modalController.getTop();
          if (existingModal) {
            console.log('Closing existing modal...');
            await existingModal.dismiss();
          }

          const modal = await this.modalController.create({
            component: ScheduleModalComponent,
            componentProps: {
              schedule: schedule || this.initializeEmptySchedule(), // Fallback si no hay horario
              uid: this.uid
            }
          });
          console.log('Modal created:', modal);
          await modal.present();
          console.log('Modal presented');
        },
        (error) => {
          console.error('Error fetching schedule:', error);
        }
      );
    } catch (error) {
      console.error('Error opening schedule modal:', error);
    }
  }

  initializeEmptySchedule() {
    return {
      weekDays: [
        { name: 'Domingo', open: false, hours: [] },
        { name: 'Lunes', open: false, hours: [] },
        { name: 'Martes', open: false, hours: [] },
        { name: 'Miércoles', open: false, hours: [] },
        { name: 'Jueves', open: false, hours: [] },
        { name: 'Viernes', open: false, hours: [] },
        { name: 'Sábado', open: false, hours: [] }
      ]
    };
  }
  async openOrderModal() {
    const modal = await this.modalController.create({
      component: OrderFormModalComponent,
      componentProps: {
        usuario: this.usuario,
        uid: this.uid
      }
    });
    return await modal.present();
  }

  closeModal() {
    this.modalController.dismiss();
  }
}
