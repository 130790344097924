import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { SetAdminComponent } from './backend/set-admin/set-admin.component';
import { RegistroComponent } from './pages/registro/registro.component';
import { LoginComponent } from './pages/login/login.component';
import {canActivate} from '@angular/fire/auth-guard';
import { map } from 'rxjs/operators';
import { StartComponent } from './pages/start/start.component';
import { OptionsLoginComponent } from './pages/options-login/options-login.component';
import { AyudaComponent } from './pages/ayuda/ayuda.component';
import { LupaComponent } from './pages/lupa/lupa.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { FiltrosComponent } from './pages/filtros/filtros.component';
import { FavoritosComponent } from './pages/favoritos/favoritos.component';
import { CardComponent } from './componentes/card/card.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { MystoreComponent } from './pages/mystore/mystore.component';
import { LoginMystoreComponent } from './pages/login-mystore/login-mystore.component';
import { HomeWebComponent } from './pages/home-web/home-web.component';
import { DestakComponent } from './pages/destak/destak.component';
import { QuienesSomosComponent } from './pages/quienes-somos/quienes-somos.component';
import { HomeMystoreComponent } from './pages/home-mystore/home-mystore.component';
import { AyudaMystoreComponent } from './pages/ayuda-mystore/ayuda-mystore.component';
import { AyudaComercioComponent } from './pages/ayuda-comercio/ayuda-comercio.component';
import { AyudaUsuarioComponent } from './pages/ayuda-usuario/ayuda-usuario.component';
import { ContactoMystoreComponent } from './pages/contacto-mystore/contacto-mystore.component';
import { ComercioMystoreComponent } from './pages/comercio-mystore/comercio-mystore.component';
import { BackofficeClienteComponent } from './pages/backoffice-cliente/backoffice-cliente.component';
import { MapComponent } from './pages/map/map.component';
import { ContactComponent } from './componentes/contact/contact.component';
import { SendEmailComponent } from './pages/send-email/send-email.component';
import { CarritoComponent } from './pages/carrito/carrito.component';
import { StarComponent } from './pages/star/star.component';
import { AdminComponent } from './backend/admin/admin.component';
import { InstaComponent } from './pages/insta/insta.component';
import { OrderOnlineComponent } from './pages/order-online/order-online.component';
import { ShopComponent } from './pages/shop/shop.component';
import { CartComponent } from './componentes/cart/cart.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { OrderDetailComponent } from './pages/order-detail/order-detail.component';
import { OrderFavoritesComponent } from './pages/order-favorites/order-favorites.component';
import { RidersComponent } from './pages/riders/riders.component';
import { ShoppersComponent } from './pages/shoppers/shoppers.component';
import { HomeUserComponent } from './pages/home-user/home-user.component';
const isAdmin = (next: any) => map( (user: any) => !!user && 'm1fH1hpHvbNx4JMHJ7rwAEHnp0s1' === user.uid);
const isUser = (next: any) => map( (user: any) => !!user === !null);
const noUser = (next: any) => map( (user: any) => user === null);



const routes: Routes = [

  {path: 'admin', component:  AdminComponent, ...canActivate(isAdmin)},
  {path: 'insta', component:  InstaComponent},
  {path: 'start', component:  StartComponent},
  {path: 'map', component:  MapComponent},
  {path: 'formulario', component:  ContactComponent},
  {path: 'home', component:  HomeComponent},
  {path: 'star', component:  StarComponent},
  {path: 'riders', component:  RidersComponent},
  {path: 'favoritos', component:  FavoritosComponent},
  {path: 'carrito', component:  CarritoComponent},
  {path: 'send-email', component:  SendEmailComponent},
  {path: 'backoffice-cliente', component:  BackofficeClienteComponent},
  {path: 'commerce-mystore', component:  ComercioMystoreComponent},
  {path: 'contacto-mystore', component:  ContactoMystoreComponent},
  {path: 'ayuda-comercio', component:  AyudaComercioComponent},
  {path: 'ayuda-usuario', component:  AyudaUsuarioComponent},
  {path: 'ayuda-mystore', component:  AyudaMystoreComponent},
  {path: 'home-mystore', component:  HomeMystoreComponent},
  {path: 'quienes-somos', component:  QuienesSomosComponent},
  {path: 'home-web', component:  HomeWebComponent},
  {path: 'login-mystore', component:  LoginMystoreComponent},
  {path: 'mystore', component:  MystoreComponent},
  {path: 'perfil', component:  PerfilComponent,  ...canActivate(isUser)},
  {path: 'card', component:  CardComponent},
  {path: 'destak', component:  DestakComponent},
  {path: 'favoritos', component:  FavoritosComponent},
  {path: 'filtros', component:  FiltrosComponent},
  {path: 'lupa', component:  LupaComponent},
  {path: 'ayuda', component:  AyudaComponent},
  { path: 'order-online', component: OrderOnlineComponent },
  { path: 'orders', component: OrdersComponent},
  { path: 'orderDetail', component: OrderDetailComponent},
  { path: 'order-favorite', component: OrderFavoritesComponent},
  {path: 'shop', component:  ShopComponent},
  {path: 'shoppers', component:  ShoppersComponent},
  {path: 'cart', component:  CartComponent},
  {path: 'contacto', component:  ContactoComponent},
  {path: 'options-login', component:  OptionsLoginComponent,...canActivate(noUser)},
  {path: 'set-admin', component:  SetAdminComponent, ...canActivate(isAdmin)},
  {path: 'registro', component:  RegistroComponent,...canActivate(noUser)},
  {path: 'login', component:  LoginComponent},
  {path: 'home-user', component:  HomeUserComponent},
  {path: '', component:  StartComponent},
  {path: '**', redirectTo: 'home',pathMatch: 'full'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
