import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuItem } from "../models";
import { FirebaseauthService } from 'src/app/services/firebaseauth.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private cart = new BehaviorSubject<MenuItem[]>([]);
  cart$ = this.cart.asObservable();
  private uid: string;

  constructor(public firebaseauthService: FirebaseauthService) {
    this.firebaseauthService.stateAuth().subscribe(user => {
      if (user) {
        this.uid = user.uid;
      } else {
        this.uid = null;
      }
    });
  }

  addToCart(item: MenuItem) {
    if (!this.uid) {
      console.error('User is not authenticated');
      return;
    }
    const currentCart = this.cart.value;
    currentCart.push(item);
    this.cart.next(currentCart);
  }

  removeFromCart(item: MenuItem) {
    if (!this.uid) {
      console.error('User is not authenticated');
      return;
    }
    const currentCart = this.cart.value.filter(cartItem => cartItem !== item);
    this.cart.next(currentCart);
  }

  clearCart() {
    if (!this.uid) {
      console.error('User is not authenticated');
      return;
    }
    this.cart.next([]);
  }

  getCart() {
    if (!this.uid) {
      console.error('User is not authenticated');
      return [];
    }
    return this.cart.value;
  }
}
