import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MenuItem, Order } from '../models';
import firebase from 'firebase/app';
import 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(public database: AngularFirestore) {}

  createDoc(data: any, path: string, id: string) {
    const collection = this.database.collection(path);
    return collection.doc(id).set(data);
  }

  getDoc<tipo>(path: string, id: string) {
    const collection = this.database.collection(path);
    return collection.doc<tipo>(id).valueChanges();
  }

  getOrders<tipo>(path: string): Observable<tipo[]> {
    const collection = this.database.collection<tipo>(path);
    return collection.valueChanges();
  }

  getUsers<tipo>(path: string): Observable<tipo[]> {
    const collection = this.database.collection<tipo>(path);
    return collection.valueChanges();
  }

  getOrdersExcludingFavorites<tipo extends Order>(path: string, excludedOrderIds: string[]): Observable<tipo[]> {
    const collection = this.database.collection<tipo>(path);
    return collection.valueChanges().pipe(
      map(orders => orders.filter(order => !excludedOrderIds.includes(order.id)))
    );
  }

  getAllFavorites(): Observable<string[]> {
    return this.database.collectionGroup('ordersFavorites').valueChanges().pipe(
      map((favorites: any[]) => favorites.map(favorite => favorite.id))
    );
  }

  setDocument(data: any, path: string) {
    const doc = this.database.doc(path);
    return doc.set(data, { merge: true });
  }

  getSchedule(uid: string): Observable<any> {
    const path = `Usuario/${uid}/horarioSemanal/schedule`;
    console.log('Fetching document from path:', path);
    return this.database.doc<any>(path).valueChanges();
  }

  deleteSchedule(uid: string) {
    const path = `Usuario/${uid}/horarioSemanal/schedule`;
    return this.database.doc(path).delete();
  }

  deleteMenuItem(uid: string, itemId: string) {
    const path = `Usuario/${uid}/menuItems/${itemId}`;
    return this.database.doc(path).delete();
  }

  updateMenuItem(product: MenuItem) {
    const path = `Usuario/${product.uid}/menuItems/${product.id}`;
    return this.database.doc(path).update(product);
  }

  getDocCliente<tipo>(path: string, id: string) {
    const collection = this.database.collection(path, ref => ref.where("categoria", ">=", "servicio").orderBy('distance'));
    return collection.doc<tipo>(id).valueChanges();
  }

  deleteDoc(path: string, id: string) {
    const collection = this.database.collection(path);
    return collection.doc(id).delete();
  }

  updateDoc(data: any, path: string, id: string) {
    const collection = this.database.collection(path);
    return collection.doc(id).update(data);
  }

  updateDocRider(data: any, path: string, id: string) {
    return this.database.collection(path).doc(id).update(data);
  }

  async addBlockedUser(currentUserId: string, blockedUserId: string) {
    const userDoc = this.database.collection('Usuario').doc(currentUserId);
    await userDoc.update({
      blockedUsers: firebase.firestore.FieldValue.arrayUnion(blockedUserId)
    });
  }

  async removeBlockedUser(currentUserId: string, blockedUserId: string) {
    const userDoc = this.database.collection('Usuario').doc(currentUserId);
    await userDoc.update({
      blockedUsers: firebase.firestore.FieldValue.arrayRemove(blockedUserId)
    });
  }

  getId() {
    return this.database.createId();
  }

  getCollection<tipo>(path: string): Observable<tipo[]> {
    const collection = this.database.collection<tipo>(path);
    return collection.valueChanges();
  }

  getClient<tipo>(path: string) {
    const collection = this.database.collection<tipo>(path, ref => ref.where("categoria", "==", "producto"));
    return collection.valueChanges();
  }

  getServices<tipo>(path: string) {
    const collection = this.database.collection<tipo>(path, ref => ref.where("categoria", "==", "servicio"));
    return collection.valueChanges();
  }

  getProductsServices<tipo>(path: string) {
    const collection = this.database.collection<tipo>(path, ref => ref.where("categoria", "!=", ""));
    return collection.valueChanges();
  }

  getCollectionPaginada<tipo>(path: string, limit: number, startAt: Date) {
    if (startAt == null) {
      startAt = new Date();
      startAt.setDate(startAt.getDate() + 1);
      console.log('start at comentarios ', startAt);
    }
    const collection = this.database.collection<tipo>(path,
      ref => ref.orderBy('fecha', 'desc')
                .limit(limit)
                .startAfter(startAt)
    );
    return collection.valueChanges();
  }

  getCollectionFilter<tipo>(path: string, parametro: string, busqueda: string, parametro2: string, tipos: string[]) {
    const collection = this.database.collection<tipo>(path,
      ref => ref.where(parametro, '==', busqueda)
                .where(parametro2, 'array-contains-any', tipos)
    );
    return collection.valueChanges();
  }

  getPaginatedClients<tipo>(path: string, limit: number, startAfter?: any): Observable<tipo[]> {
    let query = this.database.collection<tipo>(path, ref => {
      let q = ref.where('categoria', '==', 'producto').orderBy('nombre').limit(limit);
      if (startAfter) {
        q = q.startAfter(startAfter);
      }
      return q;
    });

    return query.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as tipo;
          return { ...data, id: a.payload.doc.id };
        });
      })
    );
  }
  getUserCommentsCount(uid: string): Promise<number> {
    return this.database.collection(`Usuario/${uid}/comentarios`).get().toPromise().then(snapshot => {
      return snapshot.size;
    });
  }
  incrementField(path: string, field: string, value: number) {
    const docRef = this.database.doc(path);
    return docRef.update({
      [field]: firebase.firestore.FieldValue.increment(value)
    });
  }
}
