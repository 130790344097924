import { ResourceLoader } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { Reporte, Usuario } from 'src/app/models';
import { FirestorageService } from 'src/app/services/firestorage.service';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-generar-reporte',
  templateUrl: './generar-reporte.component.html',
  styleUrls: ['./generar-reporte.component.scss'],
})
export class GenerarReporteComponent implements OnInit {

  @Input() cliente: Usuario;
  newFileQr: any;
  newReporte: Reporte = {
      id:'',
      url: '',
      nombre: '',
      fecha: null,
  }

  constructor(public firestoreService: FirestoreService,public firestorageService: FirestorageService,) { }

  ngOnInit() {
    console.log('cliente -> ,', this.cliente)
  }


  async newImageUpload(event: any, opc: number){

    console.log('opc', opc)
  
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = ((image) => {

          this.newFileQr = event.target.files[0]; 
          this.newReporte.url = image.target.result as string;
          console.log(' this.newReporte.url',  this.newReporte.url)
      });
      reader.readAsDataURL(event.target.files[0]);
    } 
  }

  async  guardar() {
    const uid = this.cliente.uid;
    const path = 'Usuario/'  + uid + '/reportes/'
    const pathImg = 'Reportes';
    const id = this.firestoreService.getId();
    this.newReporte.id = id;
    this.newReporte.fecha = new Date();
  


    let nameImg = this.newReporte.nombre + '_reporte_' + id;
    console.log('this.newFileQr', this.newFileQr)
    if (this.newFileQr !== undefined) {
          const res = await this.firestorageService.uploadImage( this.newReporte.url, pathImg, nameImg);
          console.log('res img ', res);
          this.newReporte.url = res
    }
    console.log('this.usuarios ->', this.newReporte)
    console.log('path', path)
    this.firestoreService.createDoc(this.newReporte, path,this.newReporte.id).then( res=> {
           console.log('guardado con exito');
         //  this.presentToast('guardado con exito');
          });  
        }

        volver(){
          window.location.reload();
        }

}
