import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertController, IonSegment } from '@ionic/angular';
import { FirestoreService } from '../../services/firestore.service';
import { Cliente, Usuario } from '../../models';
import { FirebaseauthService } from '../../services/firebaseauth.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import 'firebase/firestore';
import { Location } from '@angular/common';

@Component({
  selector: 'app-filtros',
  templateUrl: './filtros.component.html',
  styleUrls: ['./filtros.component.scss'],
})
export class FiltrosComponent implements OnInit {

  @ViewChild(IonSegment) segment: IonSegment;

  lat: number;
  lng: number;

  filter = false;
  admin = false;
  user = false;

  private path = 'Clientes/';

  clientes: Cliente[] = [];
  usuarios: Usuario[] = [];

  public selectedSegment: string = 'productos';

  public productos = [
    { val: 'Restaurantes', isChecked: false },
    { val: 'Restaurantes con Pedidos Online', isChecked: false },
    { val: 'Bares', isChecked: false },
    { val: 'Heladerías', isChecked: false },
    { val: 'Pastelerías', isChecked: false },
    { val: 'Discotecas', isChecked: false },
  ];

  constructor(
    private location: Location,
    private alert: AlertController,
    public firestoreService: FirestoreService,
    private firebaseauthService: FirebaseauthService,
    public geolocation: Geolocation
  ) {}

  ngOnInit() {
    this.getUid();
    this.getUserUid();
  }

  private async presentAlert(message: string) {
    const alert = await this.alert.create({
      header: 'Alerta',
      message: message,
      buttons: [
        {
          text: 'Cerrar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Cerrar alerta');
          }
        }
      ]
    });

    await alert.present();
  }

  aplicar() {
    this.filter = true;
    const tipos: string[] = [];

    this.productos.forEach(tipo => {
      if (tipo.isChecked) {
        tipos.push(this.normalizeString(tipo.val));
      }
    });

    if (tipos.length === 0) {
      this.presentAlert('Por favor, seleccione una categoría para ver los resultados.');
      this.filter = false;
      return;
    }

    console.log('filtros -> productos', tipos);

    const path = 'Usuario';
    this.firestoreService.getCollection<Usuario>(path).subscribe(res => {
      console.log('Usuarios obtenidos: ', res);
      const localesFiltrados = res.filter(local => {
        if (Array.isArray(local.tipo)) {
          const tiposNormalizados = local.tipo.map(this.normalizeString);
          console.log(`Comparando: "${tiposNormalizados}" con tipos: ${tipos}`);
          return tiposNormalizados.some(t => tipos.includes(t));
        } else if (typeof local.tipo === 'string') {
          const tipoNormalizado = this.normalizeString(local.tipo);
          console.log(`Comparando: "${tipoNormalizado}" con tipos: ${tipos}`);
          return tipos.includes(tipoNormalizado);
        } else {
          console.warn(`El campo 'tipo' no es ni un array ni una cadena en el documento: `, local);
          return false;
        }
      });
      console.log('locales -> ', localesFiltrados);
      this.filtrarByDistancia(localesFiltrados);
    });
  }

  borrar() {
    this.filter = false;
    this.usuarios = [];
    this.productos.forEach(producto => producto.isChecked = false);
  }

  goBack() {
    this.location.back();
  }

  reload() {
    location.reload();
  }

  async filtrarByDistancia(locales: Usuario[]) {
    const obtenerCoordenadas = await this.geolocation.getCurrentPosition();
    this.lat = obtenerCoordenadas.coords.latitude;
    this.lng = obtenerCoordenadas.coords.longitude;
    this.calcularMasCercano(locales);
    console.log('Locales con distancia calculada: ', locales);
    this.usuarios = locales; // No se filtra por distancia máxima, se devuelven todos los resultados
    console.log('Usuarios ordenados por distancia: ', this.usuarios);
  }

  calcularMasCercano(locales: Usuario[]) {
    console.log('calcular mas cercano');
    locales.forEach(cliente => {
      const latTienda = cliente.latitud;
      const longTienda = cliente.longitud;
      const R = 6371; // km

      const dLat = this.toRad(latTienda - this.lat);
      const dLon = this.toRad(longTienda - this.lng);

      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.toRad(this.lat)) * Math.cos(this.toRad(latTienda)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const de = R * c;
      cliente.distancia = de;
      console.log(`Distancia calculada para ${cliente.nombre}: ${de} km`);
    });

    locales.sort((n1, n2) => n1.distancia - n2.distancia);
  }

  toRad(grados: number) {
    return grados * Math.PI / 180;
  }

  getUid() {
    this.firebaseauthService.stateAuth().subscribe(res => {
      if (res !== null) {
        this.admin = res.uid === 'yNHV4zMO2EPoTwSCDh8kUcvjq612';
      } else {
        this.admin = false;
      }
    });
  }

  getUserUid() {
    this.firebaseauthService.stateAuth().subscribe(res => {
      this.user = res !== null;
    });
  }

  segmentChanged(event: any) {
    this.selectedSegment = event.target.value;
  }

  normalizeString(str: string): string {
    if (typeof str !== 'string') return '';
    return str.toLowerCase()
      .replace(/[áàäâã]/g, 'a')
      .replace(/[éèëê]/g, 'e')
      .replace(/[íìïî]/g, 'i')
      .replace(/[óòöôõ]/g, 'o')
      .replace(/[úùüû]/g, 'u')
      .replace(/ñ/g, 'n')
      .replace(/ç/g, 'c');
  }
}
