import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Comentario } from '../card/card.component';


@Component({
  selector: 'app-modal-comentario',
  templateUrl: './modal-comentario.component.html',
  styleUrls: ['./modal-comentario.component.scss'],
})
export class ModalComentarioComponent {
  @Input() comentario: Comentario;

  constructor(public modalController: ModalController) {}

  closeModal() {
    this.modalController.dismiss();
  }
}
