import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FirestoreService } from '../../services/firestore.service';
import { Usuario } from '../../models';
import { FirebaseauthService } from '../../services/firebaseauth.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';

@Component({
  selector: 'app-lupa',
  templateUrl: './lupa.component.html',
  styleUrls: ['./lupa.component.scss'],
})
export class LupaComponent implements OnInit {  
  latitud: number;
  longitud: number;

  user = false;
  admin = false;
  comercioServicio = false;

  private path = 'Usuario/';

  usuarios: Usuario[] = [];
  allUsuarios: Usuario[] = []; // Almacena todos los usuarios para el filtrado

  constructor(
    public firestoreService: FirestoreService,
    private firebaseauthService: FirebaseauthService,
    public geolocation: Geolocation,
    private router: Router
  ) {}

  ngOnInit() {
    this.initializeComponent();
  }

  async initializeComponent() {
    await this.geolocal();
    this.checkAuthState();
    this.loadUsuarios();
  }

  filtrar(ev: any) {
    const val = ev.detail.value;
    if (val && val.trim() !== '') {
      const filterValue = val.trim().toLowerCase();
      this.usuarios = this.allUsuarios.filter(usuario => 
        usuario.nombre.toLowerCase().includes(filterValue));
    } else {
      this.usuarios = [...this.allUsuarios];
    }
    this.sortUsuariosByDistance();
  }

  clearSearch(searchbar) {
    searchbar.value = '';
    this.usuarios = [...this.allUsuarios]; // Restablece la lista de usuarios después de limpiar la barra de búsqueda
    this.sortUsuariosByDistance();
    this.router.navigate(['/home']); // Navega a la página de inicio
  }

  loadUsuarios() {
    this.firestoreService.getClient<Usuario>(this.path).subscribe({
      next: res => {
        if (this.latitud !== null && this.longitud !== null) {
          this.allUsuarios = res;
          this.usuarios = [...this.allUsuarios];
          this.sortUsuariosByDistance();
        } else {
          console.error('No hay coordenadas');
        }
      },
      error: err => {
        console.error('Error al cargar usuarios:', err);
      }
    });
  }

  sortUsuariosByDistance() {
    if (this.latitud !== null && this.longitud !== null) {
      this.usuarios.sort((a, b) => {
        const distanciaA = calcularDistancia(this.latitud, this.longitud, a.latitud, a.longitud);
        const distanciaB = calcularDistancia(this.latitud, this.longitud, b.latitud, b.longitud);
        return distanciaA - distanciaB;
      });
    }
  }

  checkAuthState() {
    this.firebaseauthService.stateAuth().subscribe({
      next: res => {
        if (res !== null) {
          this.user = true;
          this.admin = res.uid === 'aKcLIeSZNVb8XbYSxuZxUQWtzdH3';
        } else {
          this.user = false;
          this.admin = false;
        }
      },
      error: err => {
        console.error('Error al verificar el estado de autenticación:', err);
      }
    });
  }

  async geolocal() {
    try {
      const obtenerCoordenadas = await this.geolocation.getCurrentPosition();
      this.latitud = obtenerCoordenadas.coords.latitude;
      this.longitud = obtenerCoordenadas.coords.longitude;
      console.log('Coordenadas obtenidas:', this.latitud, this.longitud);
    } catch (error) {
      console.error('Error obteniendo coordenadas:', error);
    }
  }
}

function calcularDistancia(lat1: number, lon1: number, lat2: number, lon2: number): number {
  const R = 6371; // Radio de la Tierra en kilómetros
  const dLat = (lat2 - lat1) * Math.PI / 180;
  const dLon = (lon2 - lon1) * Math.PI / 180;
  const a = 
    Math.sin(dLat / 2) * Math.sin(dLat / 2) + 
    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}
