import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientesComponent } from './clientes/clientes.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { CardComponent } from './card/card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';
import { ContactComponent } from './contact/contact.component';
import { UserclientesComponent } from './userclientes/userclientes.component';
import { CardclienteComponent } from './cardcliente/cardcliente.component';
import { ItemFavoritoComponent } from './item-favorito/item-favorito.component';
import { QrComponent } from './qr/qr.component';
import { GestorComentariosComponent } from './gestor-comentarios/gestor-comentarios.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { CartComponent } from './cart/cart.component';
import { HeaderComponent } from './header/header.component';
import { ProductManagementComponent } from './product-management/product-management.component';
import { ProductListComponent } from './product-list/product-list.component';
import { VideoUploadComponent } from './video-upload/video-upload.component';
import { PhotoUploadComponent } from './photo-upload/photo-upload.component';
import { ScheduleModalComponent } from './schedule-modal/schedule-modal.component';
import { EditProductModalComponent } from './edit-product-modal/edit-product-modal.component';
import { PaymentPlansComponent } from './payment-plans/payment-plans.component';
import { CardRiderComponent } from './card-rider/card-rider.component';
import { OrderFormModalComponent } from './order-form-modal/order-form-modal.component';
import { CardShopperComponent } from './card-shopper/card-shopper.component';
import { ModalComentarioComponent } from './modal-comentario/modal-comentario.component';






@NgModule({
  declarations: [
    ClientesComponent,
    CardComponent,
    CardclienteComponent,
    ContactComponent,
    UserclientesComponent,
    ItemFavoritoComponent,
    QrComponent,
    GestorComentariosComponent,
    VideoPlayerComponent,
    CartComponent,
    HeaderComponent,
    ProductManagementComponent,
    ProductListComponent,
    VideoUploadComponent,
    PhotoUploadComponent,
    ScheduleModalComponent,
    EditProductModalComponent,
    PaymentPlansComponent,
    CardRiderComponent,
    OrderFormModalComponent,
    CardShopperComponent,
    HeaderComponent,
    ModalComentarioComponent
  ],

  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,  
    
    
  ], exports: [
    ClientesComponent,
    CardComponent,
    CardclienteComponent,
    ContactComponent,
    UserclientesComponent,
    ItemFavoritoComponent,
    GestorComentariosComponent,
    VideoPlayerComponent,
    CartComponent,
    HeaderComponent,
    ProductManagementComponent,
    ProductListComponent,
    VideoUploadComponent,
    PhotoUploadComponent,
    ScheduleModalComponent,
    EditProductModalComponent,
    PaymentPlansComponent,
    CardRiderComponent,
    OrderFormModalComponent,
    CardShopperComponent,
    HeaderComponent,
    ModalComentarioComponent
    ]
})


export class ComponentesModule { }
