import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, MenuController, ModalController, ToastController } from '@ionic/angular';
import { Usuario } from 'src/app/models';
import { FirestoreService } from '../../services/firestore.service';
import { FirestorageService } from '../../services/firestorage.service';
import { Subscription } from 'rxjs';
import { FirebaseauthService } from '../../services/firebaseauth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { GenerarReporteComponent } from '../componentes/generar-reporte/generar-reporte.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-set-admin',
  templateUrl: './set-admin.component.html',
  styleUrls: ['./set-admin.component.scss'],
})
export class SetAdminComponent implements OnInit {
  isUpdating = false;

  usuario: Usuario = {
    uid: '',
    nombre: '',
    responsable: '',  
    apellido: '',
    email: '',
    password: '',
    referencia: '',
    foto: '',
    foto1: '',
    foto2: '',
    latitud: '',
    longitud: '',
    hash: '',
    qr:'',    
    categoria: '',
    tipo: '',  
    telefono: '',
    pageWeb: '',
    direccion: '',
    horario: '',
    suscripcion: '',
    codPostal: '',
    ciudad: '',
    description: '',  
  };

  newFile: '';
  newFile1: '';
  newFile2: '';
  newFileQr: '';

  usuarios: Usuario[] = [];

  newUsuario: Usuario;

  enableNewUsuario = false;

  private path = 'Usuario/';
  
  newImage = '';
  newImage1 = '';
  
  loading: any;
  
  uid = '';
  
  suscriberUserInfo: Subscription;
  
  constructor(
    private sanitizer: DomSanitizer,
    public auth: AngularFireAuth, 
    public router: Router, 
    public firebaseauthService: FirebaseauthService, 
    public menucontroller: MenuController, 
    public firestoreService: FirestoreService, 
    public loadingController: LoadingController, 
    public toastController: ToastController, 
    public alertController: AlertController, 
    public firestorageService: FirestorageService,
    public modalController: ModalController
  ) { }
    
  ngOnInit() {
    this.adminUsuarios();
  }
    
  adminUsuarios() {
    this.firestoreService.getProductsServices<Usuario>(this.path).subscribe(res => {
      this.usuarios = res;
    });
  }
    
  async deleteUsuario(usuario: Usuario) {      
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Advertencia',
      message: 'Seguro deseas <strong>eliminar</strong> este usuario?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'sí',
          handler: () => {
            console.log('Confirm Okay');
            this.firestoreService.deleteDoc(this.path, usuario.uid).then(res => {
              this.presentToast('eliminado con exito');
              this.alertController.dismiss();
            }).catch(error => {
              this.presentToast('no se pudo eliminar');
            });
          }
        }
      ]
    });

    await alert.present();
  }

  async guardarUsuario() {
    const path = 'Usuario';
    const pathImg = 'Productos';
    
    let nameImg = this.newUsuario.nombre + '_portada';
    if (this.newFile !== undefined) {
      if (this.newFile.length) {
        const res = await this.firestorageService.uploadImage(this.newFile, pathImg, nameImg);
        this.newUsuario.foto = res;
      }
    }
    nameImg = this.newUsuario.nombre + '_qr';
    if (this.newFileQr !== undefined) {
      const res = await this.firestorageService.uploadImage(this.newFileQr, path, nameImg);
      this.newUsuario.qr = res;
    }
    console.log('this.usuarios ->', this.newUsuario)
    this.firestoreService.createDoc(this.newUsuario, path, this.newUsuario.uid).then(res => {
      console.log('guardado con exito');
      this.presentToast('guardado con exito');
    });  
  }
        
  async registrarse() {
    const credenciales = {
      email: this.newUsuario.email,
      password: this.newUsuario.password,
    };
    const res = await this.firebaseauthService.registrar(credenciales.email, credenciales.password).catch(err => {
      console.log('erro -> ', err);
    });
    const uid = await this.firebaseauthService.getUid();
    this.newUsuario.uid = uid;
    this.presentToast('creado con exito');
    this.guardarUser();
    console.log(uid);
  }

  async guardarUser() {
    const path = 'Usuario';
    const name = this.newUsuario.nombre;  
    this.firestoreService.createDoc(this.newUsuario, path, this.newUsuario.uid).then(res => {
      console.log('registrado con exito')
      this.presentToast('guardado con exito');
    });  
  }

  async subirReporte(newUsuario: Usuario) {
    console.log('subir reporte -> ', newUsuario);
    const modal = await this.modalController.create({
      component: GenerarReporteComponent,
      componentProps: { cliente: newUsuario }
    });
    await modal.present();
  }

  nuevo() {
    this.enableNewUsuario = true;
    this.isUpdating = false; 
    
    this.newUsuario = {
      nombre: '',
      direccion: '',
      telefono: null,
      email:'',
      codPostal: null,
      ciudad: '',
      latitud: '',
      longitud: '',
      pageWeb: '',
      responsable: '',
      description: '',
      foto: [''],
      uid: this.firestoreService.getId(),
      suscripcion: '',
      categoria: '',
      tipo: '',
      password: '',
      horario: '',
      qr: '',
      apellido: '',
      referencia: '',
      foto1: '',
      foto2: '',
      hash: '',
    };
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'guardando...',
    });
    await this.loading.present();  
  }

  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      color: 'light',
    });
    toast.present();
  }

  isShort(): boolean {
    return this.usuario.video.includes('shorts/');
  }
  

  getContainerStyle(): any {
    const baseStyle = {
      'width': this.isShort() ? '70%' : '100%',
      'position': 'relative'
    };
    return baseStyle;
  }
 
  
   sanitizedVideoUrl(): SafeResourceUrl {
    let url = this.usuario.video;
    let embedUrl = '';

    if (url.includes('shorts/')) {
      const videoId = url.split('shorts/')[1].split('?')[0];
      embedUrl = `https://www.youtube.com/embed/${videoId}`;
    } else {
      embedUrl = url.replace('watch?v=', 'embed/');
    }

    return this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
  }
  

  async newImageUpload(event: any, opc: number) {
    console.log("opc", opc);
  
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (image) => {
        let previewElement: HTMLImageElement;
        switch (opc) {
          case 0:
            this.newFile = event.target.files[0];
            console.log("opc foto principal");
            this.usuario.foto = image.target.result as string;
            previewElement = document.getElementById('preview-main') as HTMLImageElement;
            break;
          case 1:
            this.newFile1 = event.target.files[0];
            console.log("opc foto 1");
            this.usuario.foto1 = image.target.result as string;
            previewElement = document.getElementById('preview-1') as HTMLImageElement;
            break;
          case 2:
            this.newFile2 = event.target.files[0];
            console.log("opc foto 2");
            this.usuario.foto2 = image.target.result as string;
            previewElement = document.getElementById('preview-2') as HTMLImageElement;
            break;
          default:
            console.log("Opción no válida");
            return;
        }
        if (previewElement) {
          previewElement.src = image.target.result as string;
          previewElement.style.display = 'block';
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }
}

