import { Component, Input, OnDestroy, OnInit, ViewChild, ElementRef, NgZone, ChangeDetectorRef, AfterViewInit } from "@angular/core";
import { ModalController, AlertController, ToastController } from "@ionic/angular";
import { Cliente, ClienteFavorito, Usuario } from "../../models";
import { FirestoreService } from "../../services/firestore.service";
import { FirebaseauthService } from "../../services/firebaseauth.service";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { FirestorageService } from "../../services/firestorage.service";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ModalComentarioComponent } from '../modal-comentario/modal-comentario.component'; // Importa tu componente modal

@Component({
  selector: "app-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"],
})
export class CardComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('endOfPage') endOfPage: ElementRef;
  @Input() clienteFavorito: ClienteFavorito;
  @Input() cliente: Cliente;
  @Input() usuario: Usuario;
  @ViewChild('videoPlayer') videoPlayer: ElementRef;
  currentImageIndex = 0;
  fotos: string[] = [];
  autoChangeTimer: any;

  valoracionTotal = 0;
  comentariosTotal = 0;

  coment = true;
  user = false;
  comentario = "";
  newComentario: Comentario;
  cargar = false;
  img: any;
  newFile: any;
  comentarios: Comentario[] = [];

  suscriber: Subscription;
  suscriberUserInfo: Subscription;

  enableMore = true;
  numComentatios = 3;

  videoUrl: SafeResourceUrl;

  valor: number; // Declaración de la propiedad valor

  constructor(
    private sanitizer: DomSanitizer,
    private zone: NgZone,
    private cdr: ChangeDetectorRef,
    public toastController: ToastController,
    public router: Router,
    public modalController: ModalController,
    public firestoreService: FirestoreService,
    private firebaseauthService: FirebaseauthService,
    public geolocation: Geolocation,
    public firestorageService: FirestorageService,
    public alertController: AlertController
  ) {
    this.getUserUid();
  }

  ngOnInit() {
    this.initializeImages();
    this.videoUrl = this.sanitizedVideoUrl();
    this.loadComentariosUsuarios();
    this.loadComentariosAll();
  }

  ngAfterViewInit() {
    this.startAutoChange();
  }

  ngOnDestroy() {
    if (this.autoChangeTimer) {
      clearInterval(this.autoChangeTimer);
    }
    if (this.suscriber) {
      this.suscriber.unsubscribe();
    }
  }

  closeModalAndRedirect() {
    if (this.usuario && this.usuario.orderOnline) {
      this.modalController.dismiss().then(() => {
        this.router.navigate(['/order-online', this.usuario.uid]);
      }).catch(error => {
        console.error('Error al cerrar el modal:', error);
      });
    } else {
      console.warn('El usuario no tiene permitido hacer pedidos online');
    }
  }

  initializeImages() {
    this.fotos = [this.usuario.foto, this.usuario.foto1, this.usuario.foto2].filter(Boolean);
    // Añadir la primera imagen al final para permitir un ciclo fluido
    this.fotos = [...this.fotos, this.fotos[0]];
  }

  showNextImage() {
    this.zone.runOutsideAngular(() => {
      this.currentImageIndex++;
      if (this.currentImageIndex >= this.fotos.length - 1) {
        this.currentImageIndex = 0; 
        this.skipTransition();
      }
      this.cdr.detectChanges();
    });
  }

  skipTransition() {
    const slider: HTMLElement = document.querySelector('.image-slider');
    if (slider) {
      slider.style.transition = 'none';
      slider.style.transform = `translateX(-${this.currentImageIndex * 100}%)`;
      setTimeout(() => {
        slider.style.transition = 'transform 0.5s ease-in-out';
      }, 50);
    }
  }

  startAutoChange() {
    this.zone.runOutsideAngular(() => {
      this.autoChangeTimer = setInterval(() => {
        this.showNextImage();
      }, 3000);
    });
  }

  isShort(): boolean {
    return this.usuario.video.includes('shorts/');
  }

  isYouTube(): boolean {
    return this.usuario.video.includes('youtube.com') || this.usuario.video.includes('youtu.be');
  }

  isInstagram(): boolean {
    return this.usuario.video.includes('instagram.com');
  }

  isInstagramPost(): boolean {
    return this.usuario.video.includes('/p/');
  }

  isInstagramReel(): boolean {
    return this.usuario.video.includes('/reels/');
  }

  getContainerStyle(): any {
    if (this.isInstagram()) {
      return {
        'width': '100%',
        'height': '670px',
        'position': 'relative',
        'padding-top': '120%'
      };
    }
    return {
      'width': '100%',
      'position': 'relative',
      'padding-top': this.isShort() ? '100%' : '60%',
      'height': this.isShort() ? '650px' : '300px'
    };
  }  
  
  sanitizedVideoUrl(): SafeResourceUrl {
    let url = this.usuario.video;
    let embedUrl = '';

    if (url.includes('shorts/')) {
      const videoId = url.split('shorts/')[1].split('?')[0];
      embedUrl = `https://www.youtube.com/embed/${videoId}`;
    } else if (url.includes('youtube.com') || url.includes('youtu.be')) {
      embedUrl = url.replace('watch?v=', 'embed/');
    } else if (url.includes('instagram.com')) {
      if (url.includes('/p/')) {
        const videoId = url.split('/p/')[1].split('/')[0];
        embedUrl = `https://www.instagram.com/p/${videoId}/embed`;
      } else if (url.includes('/reels/')) {
        const videoId = url.split('/reels/')[1].split('/')[0];
        embedUrl = `https://www.instagram.com/reel/${videoId}/embed`;
      }
    }

    return this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
  }

  loadComentarios() {
    let startAt = null;
    if (this.comentarios.length) {
      startAt = this.comentarios[this.comentarios.length - 1].fecha;
    }
    const path = "Usuario/" + this.clienteFavorito.usuario.uid + "/comentarios";
    this.suscriber = this.firestoreService
      .getCollectionPaginada<Comentario>(path, 2, startAt)
      .subscribe((res) => {
        if (res.length) {
          res.forEach((comentario) => {
            const exist = this.comentarios.find((commentExist) => commentExist.id === comentario.id);
            if (exist === undefined) {
              this.comentarios.push(comentario);
            }
          });
        }
      });
  }

  loadComentariosUsuarios() {
    let startAt = null;
  
    if (this.comentarios.length) {
      startAt = this.comentarios[this.comentarios.length - 1].fecha;
    }
    const path = "Usuario/" + this.usuario.uid + "/comentarios";
    this.suscriber = this.firestoreService
      .getCollectionPaginada<Comentario>(path, this.numComentatios, startAt)
      .subscribe((res) => {
        console.log('Comentarios cargados:', res); // Log de depuración
        if (res.length < this.numComentatios) {
          this.enableMore = false;
        }
        if (res.length) {
          res.forEach((comentario) => {
            const exist = this.comentarios.find((commentExist) => commentExist.id === comentario.id);
            if (exist === undefined) {
              comentario.showFullRespuesta = false; // Inicializa la propiedad
              comentario.comercioNombre = this.usuario.nombre; // Asigna el nombre del comercio
              comentario.comercioFoto = this.usuario.foto; // Asigna la foto del comercio
              this.comentarios.push(comentario);
            }
          });
          this.cdr.detectChanges(); // Fuerza la detección de cambios en Angular
        }
      });
  }
  
  loadComentariosAll() {
    const path = "Usuario/" + this.usuario.uid + "/comentarios";
    this.suscriber = this.firestoreService
      .getCollection<Comentario>(path)
      .subscribe((res) => {
        const comentarios = res;
        this.valoracionTotal = 0;
        comentarios.forEach((comentario) => {
          if (!isNaN(comentario.valor)) {
            const valor: number = +comentario.valor;
            this.valoracionTotal += valor;
          }
        });
        this.valoracionTotal /= comentarios.length;
        this.comentariosTotal = comentarios.length;
      });
  }
  

  openModalComentario(comment: Comentario) {
    this.modalController.create({
      component: ModalComentarioComponent,
      componentProps: { comentario: comment }
    }).then(modal => modal.present());
  }

  async addComment() {
    if (!this.user) {
      const alert = await this.alertController.create({
        cssClass: "body",
        header: "Hola!",
        message: "Necesitas iniciar sesión para valorar a los comercios",
        buttons: [{ text: "cerrar", handler: () => {} }],
      });
      await alert.present();
    } else {
      this.coment = false;
      // Scroll al final de la página
      setTimeout(() => {
        this.endOfPage.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 100);
    }
  }
  

  comentarUsuario() {
    this.user = true;
    const comentario = this.comentario;
    if (typeof this.valor === "object") {
      this.presentToast();
      return;
    }
    const path = "Usuario/" + this.usuario.uid + "/comentarios";
    const data: Comentario = {
      autor: this.firebaseauthService.datosUsuario.nombre,
      comentario,
      fecha: new Date(),
      id: this.firestoreService.getId(),
      foto: this.firebaseauthService.datosUsuario.foto,
      valor: this.valor,
      img: this.img ?? null,
      respuesta: "",
    };
    this.firestoreService.createDoc(data, path, data.id).then(() => {
      this.comentario = "";
      this.coment = true;
      // Incrementar el campo numComentarios
      const userPath = `Usuario/${this.usuario.uid}`;
      this.firestoreService.incrementField(userPath, 'numComentarios', 1);
    });
  }
  
  async presentToast() {
    const toast = await this.toastController.create({
      message: "Necesitas escoger una valoración",
      duration: 2000,
      color: "light",
    });
    toast.present();
  }

  getUserUid() {
    this.firebaseauthService.stateAuth().subscribe((res) => {
      this.user = res !== null;
    });
  }

  async newImageUpload(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.newFile = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (image) => {
        this.img = image.target.result as string;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  openDirecion() {
    const URLDireccion = `https://www.google.com/maps/search/?api=1&query=${this.usuario.latitud},${this.usuario.longitud}`;
    window.open(URLDireccion);
  }
}

export interface Comentario {
  autor: any;
  comentario: string;
  fecha: any;
  id: string;
  foto: any;
  valor: any;
  img: any;
  respuesta: string;
  showFullRespuesta?: boolean; // Nueva propiedad
  comercioNombre?: string; // Nombre del comercio que hizo la respuesta
  comercioFoto?: string; 
}

