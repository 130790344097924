import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AlertController, ToastController } from '@ionic/angular';
import { FirebaseauthService } from './firebaseauth.service';
import { FirestoreService } from './firestore.service';
import { Order, Usuario, Favorito, ClienteFavorito } from '../models';

@Injectable({
  providedIn: 'root'
})
export class FavoritosService {
  private favoriteOrdersSubject = new BehaviorSubject<Order[]>([]);
  favoriteOrders$ = this.favoriteOrdersSubject.asObservable();
  private uid = '';
  private usuario: Usuario;
  private favorito: Favorito;

  constructor(
    private firebaseauthService: FirebaseauthService,
    private firestoreService: FirestoreService,
    private alertController: AlertController,
    private toastController: ToastController
  ) {
    this.firebaseauthService.stateAuth().subscribe(res => {
      if (res !== null) {
        this.uid = res.uid;
        this.loadUsuario();
      }
    });
  }

  private loadUsuario() {
    const path = 'Usuario';
    this.firestoreService.getDoc<Usuario>(path, this.uid).subscribe(res => {
      this.usuario = res;
      this.loadFavorite();
    });
  }

  private loadFavorite() {
    const path = `Usuario/${this.uid}/favoritos`;
    this.firestoreService.getDoc<Favorito>(path, this.uid).subscribe(res => {
      if (res) {
        this.favorito = res;
        this.favoriteOrdersSubject.next(this.favorito.orders);
      } else {
        this.initFavorite();
      }
    });
  }

  private initFavorite() {
    this.favorito = {
      id: this.uid,
      email: this.usuario.email,
      usuario: this.usuario,
      orders: [],
      clientes: [],
      estado: 'activo'
    };
    this.favoriteOrdersSubject.next(this.favorito.orders);
  }

  getFavorites(): Order[] {
    return this.favoriteOrdersSubject.getValue();
  }

  addToFavorites(order: Order) {
    this.favorito.orders = [order];
    this.updateFavorite();
  }

  removeFromFavorites(order: Order) {
    this.favorito.orders = this.favorito.orders.filter(o => o.id !== order.id);
    this.updateFavorite();
  }

  hasFavorites(): boolean {
    return this.getFavorites().length > 0;
  }

  private updateFavorite() {
    const path = `Usuario/${this.uid}/favoritos`;
    this.firestoreService.createDoc(this.favorito, path, this.uid).then(() => {
      this.favoriteOrdersSubject.next(this.favorito.orders);
      this.presentToast('Favoritos actualizados');
    }).catch(err => {
      console.error('Error actualizando favoritos:', err);
      this.presentToast('Error actualizando favoritos');
    });
  }

  addCliente(usuario: Usuario) {
    const item = this.favorito.clientes.find(clienteFavorito => clienteFavorito.usuario.uid === usuario.uid);
    if (item) {
      item.cantidad++;
    } else {
      const add: ClienteFavorito = {
        cantidad: 1,
        usuario
      };
      this.favorito.clientes.push(add);
    }
    this.updateFavorite();
  }

  removeCliente(usuario: Usuario) {
    const itemIndex = this.favorito.clientes.findIndex(clienteFavorito => clienteFavorito.usuario.uid === usuario.uid);
    if (itemIndex > -1) {
      this.favorito.clientes.splice(itemIndex, 1);
      this.updateFavorite();
    }
  }

  private async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      color: 'light',
    });
    toast.present();
  }
}
