import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore.service';
import { Cliente, Favorito, Usuario } from '../../models';
import { FirebaseauthService } from '../../services/firebaseauth.service';
import { Geolocation, Geoposition } from '@ionic-native/geolocation/ngx';
import firebase from 'firebase/app';
import 'firebase/firestore';
import geohash from "ngeohash";
import { Firestore } from "@google-cloud/firestore";
import { CarritoService } from 'src/app/services/carrito.service';


@Component({
  selector: 'app-carrito',
  templateUrl: './carrito.component.html',
  styleUrls: ['./carrito.component.scss'],
})
export class CarritoComponent implements OnInit {

  lat: number;
  lng: number;
  admin = false;
  user = false;
  favorites = true;

  private path = 'Clientes/';

  clientes: Cliente[] = [];
  

  

  favorito: Favorito;

  constructor(public firestoreService: FirestoreService, private firebaseauthService: FirebaseauthService, public geolocation: Geolocation, public carritoService: CarritoService) {

  
    this.initFavorite();
    this.loadFavorito();
   
   }

  ngOnInit() {
    
  }

  loadFavorito(){

    this.carritoService.getFavorite().subscribe( res => {
      this.favorito = res;
      if(!this.favorito){
        !this.favorites;
      }else if(this.favorito){
        this.favorites;
      }
    })

    /* this.carritoService.getFavorite().then(res => {
      console.log(res)
      if(res ) {
        this.favorito = res;
      }
    }); */
  }

  initFavorite() {
    this.favorito = {
      id: '',
  email: '',

  usuario: null,
  clientes: [],
  estado: 'enviado',
    }
  }

  reload(){
    location.reload()
  }


  
  
  loadClientesProductos() { 
    if(this.user !== null){
      
      this.firestoreService.getCollection<Cliente>(this.path).subscribe( res =>{ 
        if (res !== null) {
          this.clientes = res;
          this.getUid();
          this.getUserUid();
        }else{
          console.log('no hay coordenadas');
        }      
  
        
      }); 
    }
     }
 
   loadClientesServices() { 
   
     this.firestoreService.getServices<Cliente>(this.path).subscribe( res => { 
       if (this.lat !== null)  {
         this.clientes = res;
         this.getUid();
         this.getUserUid();
       }else{
         console.log('no hay coordenadas');
       }          
     }); 
     }
   
 
   getUid() {
     this.firebaseauthService.stateAuth().subscribe( res => {
       if (res !== null) {
         if (res.uid === 'yNHV4zMO2EPoTwSCDh8kUcvjq612') { 
           this.admin = true;
       } else {
         this.admin = false;
       }
     } else {
       this.admin = false;
     }
     });
   }
 
   getUserUid() {
     this.firebaseauthService.stateAuth().subscribe( res => {
       if (res !== null) {
          
           this.user = true;
       } else {
         this.user = false;
       }
     
     });
   }
 
   async geolocal(){
     const obtenerCoordenadas = await this.geolocation.getCurrentPosition();
     this.lat = obtenerCoordenadas.coords.latitude;
     this.lng = obtenerCoordenadas.coords.longitude;
       console.log(this.lat, this.lng)
     };
 
   enableServices(){
     this.loadClientesServices()
   }
 
 
 
   }


  

  


  



  





