import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController, AlertController, ToastController } from '@ionic/angular';
import { Cliente, ClienteFavorito, Usuario } from '../../models';
import { FirestoreService } from '../../services/firestore.service';
import { FirebaseauthService } from '../../services/firebaseauth.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { FirestorageService } from '../../services/firestorage.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cardcliente',
  templateUrl: './cardcliente.component.html',
  styleUrls: ['./cardcliente.component.scss'],
})
export class CardclienteComponent implements OnInit, OnDestroy {

  @Input() clienteFavorito: ClienteFavorito;
  @Input() cliente: Cliente;
  @Input() usuario: Usuario;

  
  
  admin = false;

  coment = true;
  user = false;
  comentario = '';
  newComentario: Comentario;
  cargar = false;  
  img: any;  
  newFile: any;
  comentarios: Comentario[] = [];  

  suscriber: Subscription;
  suscriberUserInfo: Subscription;

  enableMore = true;
  numComentatios = 2;
  valoracionTotal = null;



  constructor(public toastController: ToastController, public router: Router, public modalController: ModalController, public firestoreService: FirestoreService, private firebaseauthService: FirebaseauthService, public geolocation: Geolocation, public firestorageService: FirestorageService, public alertController: AlertController) {
    this.getUserUid()
  }

  
  ngOnInit() {
    this.loadComentariosUsuarios();
   }
 
   ngOnDestroy(){
     console.log('ngOnDestry() modal')
     if(this.suscriber){
       this.suscriber.unsubscribe();
         }
   }
 
   loadComentarios() {
    let startAt = null;     
    if (this.comentarios.length) {
      startAt = this.comentarios[ this.comentarios.length - 1].fecha;
    }
   const path = 'Usuario/' + this.clienteFavorito.usuario.uid + '/comentarios';
   this.suscriber = this.firestoreService.getCollectionPaginada<Comentario>(path, 2, startAt ).subscribe( res => {
     if(res.length) {
       console.log('res -> ',  res);
       res.forEach( comentario => {
         const exist = this.comentarios.find(commentExist => {
           commentExist.id === comentario.id
         });
         if (exist === undefined) {    
         this.comentarios.push(comentario);
         }
       });
       console.log(res);
       this.getValoracionesTotal();
     } 
   });
  }
 
    loadComentariosUsuarios() {
      let startAt = null;
      
      if (this.comentarios.length) {
        startAt = this.comentarios[ this.comentarios.length - 1].fecha;
      }
      console.log(this.clienteFavorito)
     const path = 'Usuario/' + this.clienteFavorito.usuario.uid + '/comentarios';
     this.suscriber = this.firestoreService.getCollectionPaginada<Comentario>(path, this.numComentatios, startAt ).subscribe( res => {
       if (res.length < this.numComentatios ) {
           this.enableMore = false;
       }
       if(res.length) {
         res.forEach( comentario => {
           const exist = this.comentarios.find(commentExist => {
             return commentExist.id === comentario.id
           });
           if (exist === undefined) {    
           this.comentarios.push(comentario);
           }
         });
         console.log(res);
       } 
     });
    }
 
    async addComment() {  
      if(this.user !== true) {
        const alert = await this.alertController.create({
          cssClass: 'body',
          header: 'Necesitas iniciar sesión para valorar a los comercios',
          message: '¿Te llevamos al login?',
          buttons: [
            {
              text: 'no',
              role: 'cancel',
              cssClass: '',
              handler: (blah) => {
              }
            }, {
              text: 'sí',
              handler: () => { 
               this.router.navigate(['login']);
              }
            }
          ]
        });
        
        await alert.present();
      }else {
        this.coment = false
        
        }
    };
    
    
    async presentToast(msg: string) {
      const toast = await this.toastController.create({
        message: msg,
        duration: 2000,
        color: 'light',
      });
      toast.present();
    }
 
    comentar(){
      this.user = true;
     const comentario = this.comentario;
     console.log('comentario ->', comentario);
     const path = 'Usuario/' + this.clienteFavorito.usuario.uid + '/comentarios';
     const data: Comentario = {
       autor: this.firebaseauthService.datosUsuario.nombre,
       comentario: comentario,
       fecha: new Date(),
       id: this.firestoreService.getId(),
       foto: this.firebaseauthService.datosUsuario.foto,
       valor: this.valor,
       img: this.img,
       respuesta : ''
     }
     this.firestoreService.createDoc(data, path, data.id).then( () => {
             console.log('cometario enviado');
             this.comentario = '';
     });
     }
 
    comentarUsuario(){
      this.user = true;
     const comentario = this.comentario;
     console.log('comentario ->', comentario);
     const path = 'Usuario/' + this.clienteFavorito.usuario.uid + '/comentarios';
     const data: Comentario = {
       autor: this.firebaseauthService.datosUsuario.nombre,
       comentario: comentario,
       fecha: new Date(),
       id: this.firestoreService.getId(),
       foto: this.firebaseauthService.datosUsuario.foto,
       valor: this.valor,
       img: (this.img !== undefined) ? this.img : null,
       respuesta : ''
     }
           this.firestoreService.createDoc(data, path, data.id).then( () => {
             console.log('cometario enviado');
             this.comentario = '';
           });
     }

     getUid() {
      this.firebaseauthService.stateAuth().subscribe( res => {
        if (res !== null) {
          if (res.uid === 'yNHV4zMO2EPoTwSCDh8kUcvjq612') { 
            this.admin = true;
        } else {
          this.admin = false;
        }
      } else {
        this.admin = false;
      }
      });
    }
 
     getUserUid() {
       this.firebaseauthService.stateAuth().subscribe( res => {
         if (res !== null) {
            
             this.user = true;
         } else {
           this.user = false;
         }
       
       });
     }
 
     async newImageUpload(event: any){
 
       if (event.target.files && event.target.files[0]) {
         this.newFile = event.target.files[0];
         const reader = new FileReader();
         reader.onload = ((image) => {
           this.img = image.target.result as string;
         });
         reader.readAsDataURL(event.target.files[0]);
       } 
     }
 
 
 
     reload(){
       location.reload()
     }


     getValoracionesTotal() {
        this.valoracionTotal = 0;
        this.comentarios.forEach( comentario => { 
           if (comentario.valor) {
            this.valoracionTotal = this.valoracionTotal + comentario.valor;
          
           }


        })
        this.valoracionTotal = this.valoracionTotal/this.comentarios.length;
        console.log("this.valoracionTotal", this.valoracionTotal);

     }


 
     public valor = [
       { val: 'Excelente', isChecked: true },
       { val: 'Muy bueno', isChecked: false },
       { val: 'Normal', isChecked: false },
       { val: 'Deficiente', isChecked: false },
       { val: 'Pésimo', isChecked: false },
     ];
 
 
    }
 
 
   
 
    interface Comentario {
     autor: any;
     comentario: string;
     fecha: any;
     id: string;
     foto: any;
     valor: any;
     img: any;
     respuesta: string
    }
 


  

