import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore.service';
import { Usuario } from '../../models';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { ToastController, LoadingController } from '@ionic/angular';
import { FirebaseauthService } from 'src/app/services/firebaseauth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {  

  lat: number | null;
  lng: number | null;
  usuarios: Usuario[] = [];
  comerciosProductosLoad: Usuario[] = [];
  loading: HTMLIonLoadingElement;
  pageSize = 10;  // Número de items a cargar por página
  lastVisible: any = null;
  isLoading = false;
  isAuthenticated: boolean = false;

  private readonly path = 'Usuario/';

  constructor(
    private firestoreService: FirestoreService,
    private geolocation: Geolocation,
    private toastController: ToastController,
    private loadingController: LoadingController,
    private firebaseauthService: FirebaseauthService
  ) {}

  async ngOnInit() {
    this.initializeRotatingImage();
    console.log('ngOnInit triggered');
    await this.initLocation();
    this.loadComerciosProductos();
    this.checkAuthentication();
  }

  private async initLocation() {
    const latString = localStorage.getItem('lat');
    const lngString = localStorage.getItem('lng');
    this.lat = latString !== 'null' ? parseFloat(latString) : null;
    this.lng = lngString !== 'null' ? parseFloat(lngString) : null;

    if (this.lat === null || this.lng === null) {
      await this.geolocal();
    }
  }

  private async checkAuthentication() {
    this.firebaseauthService.stateAuth().subscribe((res) => {
      this.isAuthenticated = res !== null;
    });
  }

  private async calcularMasCercano(comercios: Usuario[]): Promise<void> {
    try {
      if (this.lat !== null && this.lng !== null) {
        comercios.forEach(comercio => {
          comercio.distancia = this.calculateDistance(comercio.latitud, comercio.longitud);
        });
        comercios.sort((n1, n2) => n1.distancia - n2.distancia);
      } else {
        console.log('No se puede obtener ubicación. Filtrando por relevancia.');
        await this.filtrarPorComentarios(comercios);
      }
    } catch (error) {
      this.presentToast('No podemos obtener tu ubicación');
      console.error('Error calculating nearest stores:', error);
    }
  }

  private async filtrarPorComentarios(comercios: Usuario[]): Promise<void> {
    try {
      for (let comercio of comercios) {
        const count = await this.firestoreService.getUserCommentsCount(comercio.uid);
        comercio.numComentarios = count;
      }
      comercios.sort((n1, n2) => (n2.numComentarios || 0) - (n1.numComentarios || 0));
    } catch (error) {
      console.error('Error fetching comment counts:', error);
    }
  }

  private calculateDistance(latTienda: number, longTienda: number): number {
    const R = 6371; // km
    const dLat = this.toRad(latTienda - this.lat);
    const dLon = this.toRad(longTienda - this.lng);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.toRad(this.lat)) * Math.cos(this.toRad(latTienda)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  }

  private toRad(grados: number): number {
    return grados * Math.PI / 180;
  }

  private async loadComerciosProductos(event?: any) {
    if (this.isLoading) return;
    this.isLoading = true;
    console.log('loadComerciosProductos() called');

    try {
      await this.presentLoading('Cargando comercios...');
      this.firestoreService.getPaginatedClients<Usuario>(this.path, this.pageSize, this.lastVisible).subscribe(async res => {
        console.log('Datos obtenidos de Firestore:', res);
        if (res.length === 0) {
          if (event && event.target) {
            event.target.disabled = true;
          }
          this.dismissLoading();
          this.isLoading = false;
          return;
        }

        if (this.lat !== null && this.lng !== null) {
          await this.calcularMasCercano(res);
        } else {
          await this.filtrarPorComentarios(res);
        }

        console.log('comercios -> ', res);

        // Eliminar duplicados
        res.forEach(comercio => {
          if (!this.comerciosProductosLoad.some(c => c.uid === comercio.uid)) {
            this.comerciosProductosLoad.push(comercio);
          }
        });

        this.usuarios = [...this.comerciosProductosLoad];
        this.dismissLoading();

        if (res.length < this.pageSize) {
          if (event && event.target) {
            event.target.disabled = true;
          }
        } else {
          this.lastVisible = res[res.length - 1];
        }

        if (event && event.target) {
          event.target.complete();
        }
        this.isLoading = false;
      }, error => {
        this.presentToast('Error al obtener datos de Firestore');
        console.error('Error fetching Firestore data:', error);
        if (event && event.target) {
          event.target.complete();
        }
        this.dismissLoading();
        this.isLoading = false;
      });
    } catch (error) {
      this.presentToast('Error al cargar comercios');
      console.error('Error loading stores:', error);
      this.dismissLoading();
      this.isLoading = false;
    }
  }

  private async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      color: 'light',
    });
    toast.present();
  }

  private async presentLoading(texto: string) {
    if (this.loading) {
      await this.dismissLoading();
    }
    console.log('presentLoading');
    this.loading = await this.loadingController.create({
      message: texto,
    });
    await this.loading.present();
  }

  private async dismissLoading() {
    if (this.loading) {
      console.log('dismissLoading()');
      await this.loading.dismiss();
      this.loading = null;
    }
  }

  private async geolocal() {
    try {
      const obtenerCoordenadas = await this.geolocation.getCurrentPosition();
      this.lat = obtenerCoordenadas.coords.latitude;
      this.lng = obtenerCoordenadas.coords.longitude;
      localStorage.setItem('lat', this.lat.toString());
      localStorage.setItem('lng', this.lng.toString());
      console.log('Ubicación obtenida:', this.lat, this.lng);
    } catch (error) {
      this.presentToast('Error al obtener la ubicación');
      console.error('Error fetching location:', error);
    }
  }

  loadData(event) {
    this.loadComerciosProductos(event);
  }
  
  initializeRotatingImage() {
    const rotatingImage = document.getElementById('rotatingImage');

    if (rotatingImage) {
      // Establecer la duración de la animación inicial
      let animationDuration = 10; // 10 segundos

      // Aplicar la duración de la animación
      rotatingImage.style.animationDuration = `${animationDuration}s`;

      // Event listener para cambiar la velocidad al pasar el dedo sobre la imagen
      rotatingImage.addEventListener('touchmove', (event: TouchEvent) => {
        // Calcular la nueva velocidad basado en el movimiento del dedo
        const touchSpeed = Math.max(1, Math.min(30, 30 / (event.touches[0].clientY || 1)));
        
        // Actualizar la duración de la animación
        rotatingImage.style.animationDuration = `${touchSpeed}s`;
      });

      // Event listener para restaurar la velocidad cuando se quita el dedo
      rotatingImage.addEventListener('touchend', () => {
        rotatingImage.style.animationDuration = `${animationDuration}s`;
      });
    }



  }
}

