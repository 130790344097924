import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Router } from '@angular/router';
import 'firebase/firestore';
import { FirestoreService } from 'src/app/services/firestore.service';
import { Usuario } from 'src/app/models';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
})
export class StartComponent implements OnInit {
  lat: any;
  lng: any;
  usuarios = []

  constructor(
    public loadingController: LoadingController,
    public alertController: AlertController,
    public geolocation: Geolocation, 
    public router: Router,
    public firestoreService: FirestoreService
  ) { 
    this.presentLoading();
  }

  ngOnInit() { }

  async presentLoading() {
    const loading = await this.loadingController.create({
      cssClass: 'loading',
      message: '',
      duration: 1000
    });
    await loading.present();
    await loading.onDidDismiss();

    this.checkGeolocation();
  }

  async checkGeolocation() {
    try {
      const position = await this.geolocation.getCurrentPosition();
      this.lat = position.coords.latitude;
      this.lng = position.coords.longitude;
      localStorage.setItem('lat', this.lat.toString());
      localStorage.setItem('lng', this.lng.toString());
      console.log('Geolocation successful', this.lat, this.lng);
      this.router.navigate(['home']);
    } catch (error) {
      console.error('Error fetching location:', error);
      localStorage.setItem('lat', 'null');
      localStorage.setItem('lng', 'null');
      this.showGeolocationDeniedAlert();
    }
  }

  async showGeolocationDeniedAlert() {
    const alert = await this.alertController.create({
      cssClass: 'body',
      header: 'No podemos acceder a tu ubicación',
      message: 'Por favor, activa la localización. En caso contrario, se asignará una ubicación por defecto.',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: '',
          handler: () => {
            this.router.navigate(['home']);
          }
        }, {
          text: 'Ok',
          handler: () => {            
            this.router.navigate(['home']);
          }
        }
      ]
    });

    await alert.present();
  }
}
