import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Order } from '../models';
import { AngularFirestore } from '@angular/fire/firestore';
import { FirebaseauthService } from './firebaseauth.service';

@Injectable({
  providedIn: 'root'
})
export class OrderFavoritesService {
  private favoriteOrders = new BehaviorSubject<Order[]>([]);
  favoriteOrders$ = this.favoriteOrders.asObservable();
  private userUid: string;

  constructor(
    private firestore: AngularFirestore,
    private authService: FirebaseauthService
  ) {
    this.authService.stateAuth().subscribe(user => {
      if (user) {
        this.userUid = user.uid;
        this.loadFavorites();
      }
    });
  }

  private loadFavorites() {
    if (this.userUid) {
      this.firestore.collection<Order>(`Usuario/${this.userUid}/favorites`).valueChanges().subscribe(orders => {
        this.favoriteOrders.next(orders);
      });
    }
  }

  addToFavorites(order: Order) {
    const currentFavorites = this.favoriteOrders.value;
    currentFavorites.push(order);
    this.favoriteOrders.next(currentFavorites);
    this.saveFavorites();
  }

  removeFromFavorites(order: Order) {
    const currentFavorites = this.favoriteOrders.value.filter(favOrder => favOrder !== order);
    this.favoriteOrders.next(currentFavorites);
    this.saveFavorites();
  }

  clearFavorites() {
    this.favoriteOrders.next([]);
    this.saveFavorites();
  }

  getFavorites() {
    return this.favoriteOrders.value;
  }

  hasFavorites(): boolean {
    return this.favoriteOrders.value.length > 0;
  }

  private saveFavorites() {
    const currentFavorites = this.favoriteOrders.value;
    if (this.userUid) {
      const favoritesCollection = this.firestore.collection(`Usuario/${this.userUid}/favorites`);
      currentFavorites.forEach(order => {
        favoritesCollection.doc(order.id).set(order);
      });
    }
  }
}
