import { Component, Input, OnInit } from "@angular/core";
import { Cliente, Usuario } from "../../models";
import {
  AlertController,
  ModalController,
  ToastController,
} from "@ionic/angular";
import { CardComponent } from "../card/card.component";
import { Router } from "@angular/router";
import { FirebaseauthService } from "src/app/services/firebaseauth.service";
import { FavoritosService } from "src/app/services/favoritos.service";

@Component({
  selector: "app-userclientes",
  templateUrl: "./userclientes.component.html",
  styleUrls: ["./userclientes.component.scss"],
})
export class UserclientesComponent implements OnInit {
  @Input() cliente: Cliente;
  @Input() usuario: Usuario;

  user = false;

  constructor(
    public favoritosService: FavoritosService,
    public modalController: ModalController,
    public alertController: AlertController,
    public router: Router,
    private firebaseauthService: FirebaseauthService,
    public toastController: ToastController
  ) {
    this.getUserUid();
  }

  ngOnInit() {}

  async openModal() {
    const modal = await this.modalController.create({
      component: CardComponent,
      componentProps: { usuario: this.usuario },
    });
    return await modal.present();
  }

  async addFavorite() {
    if (this.user !== true) {
      const alert = await this.alertController.create({
        cssClass: "body",
        header: "Necesitas iniciar sesión para guardar tus comercios favoritos",
        message: "¿Te llevamos al login?",
        buttons: [
          {
            text: "no",
            role: "cancel",
            cssClass: "",
            handler: (blah) => {},
          },
          {
            text: "sí",
            handler: () => {
              this.router.navigate(["registro"]);
            },
          },
        ],
      });

      await alert.present();
    } else {
      this.favoritosService.addCliente(this.usuario);
      this.presentToast("Añadido a favorito con exito");
    }
  }

  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      color: "light",
    });
    toast.present();
  }

  getUserUid() {
    this.firebaseauthService.stateAuth().subscribe((res) => {
      if (res !== null) {
        this.user = true;
      } else {
        this.user = false;
      }
    });
  }
}
