import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { ClienteFavorito } from 'src/app/models';
import { CarritoService } from 'src/app/services/carrito.service';
import { FirebaseauthService } from 'src/app/services/firebaseauth.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { CardComponent } from '../card/card.component';
import { CardclienteComponent } from '../cardcliente/cardcliente.component';


@Component({
  selector: 'app-item-favorito',
  templateUrl: './item-favorito.component.html',
  styleUrls: ['./item-favorito.component.scss'],
})
export class ItemFavoritoComponent implements OnInit {

  @Input() clienteFavorito: ClienteFavorito;
  @Input() botones = true;
  user = false;

  constructor(public carritoService: CarritoService, public modalController: ModalController, public alertController: AlertController, public router: Router, public firestoreService: FirestoreService, private firebaseauthService: FirebaseauthService, public toastController: ToastController,) { }

  ngOnInit() {}

  /* addFavorito() {
    console.log('addCarrito()');
    this.carritoService.addCliente(this.clienteFavorito.cliente);
  } */

  removeFavorito() {
      this.carritoService.removeCliente(this.clienteFavorito.usuario);
  }

  
  

async openModal(){
  const modal = await this.modalController.create({
    component: CardclienteComponent,
    componentProps: {cliente: this.clienteFavorito.usuario}
  });
  return await modal.present();
}

async addFavorite() {
  
  if(this.user !== true) {
    const alert = await this.alertController.create({
      cssClass: 'body',
      header: 'Necesitas iniciar sesión para guardar tus comercios favoritos',
      message: '¿Te llevamos al login?',
      buttons: [
        {
          text: 'no',
          role: 'cancel',
          cssClass: '',
          handler: (blah) => {
          }
        }, {
          text: 'sí',
          handler: () => {   
            this.router.navigate(['registro']);
          }
        }
      ]
    });
    
    await alert.present();
    }else {
      this.presentToast('Añadido a favorito con exito');
      this.carritoService.addCliente(this.clienteFavorito.usuario);
    }
};


async presentToast(msg: string) {
  const toast = await this.toastController.create({
    message: msg,
    duration: 2000,
    color: 'light',
  });
  toast.present();
}



  
    


getUserUid() {
  this.firebaseauthService.stateAuth().subscribe( res => {
    if (res !== null) {
       
        this.user = true;
    } else {
      this.user = false;
    }
  
  });
}

}
