import { Component, OnInit } from "@angular/core";
import { FirebaseauthService } from "../../services/firebaseauth.service";
import { FirestorageService } from "../../services/firestorage.service";
import { FirestoreService } from "../../services/firestore.service";
import { AngularFireAuth } from "@angular/fire/auth";
import { DomSanitizer } from "@angular/platform-browser";
import { AlertController, ToastController } from "@ionic/angular";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Usuario } from "src/app/models";

@Component({
  selector: 'app-insta',
  templateUrl: './insta.component.html',
  styleUrls: ['./insta.component.scss'],
})
export class InstaComponent implements OnInit {
  videoUrl: string = '';
  savedVideoUrl: string = '';
  suscriberUserInfo: Subscription;
  uid = "";
  tipoDeSubscription = "premium";

  usuario: Usuario = {
    uid: "",
    video: '',
    nombre: "",
    responsable: "",
    apellido: "",
    email: "",
    password: "",
    referencia: "",
    foto: "",
    foto1: "",
    qr: "",
    foto2: "",
    latitud: "",
    longitud: "",
    hash: "",
    categoria: "",
    tipo: "",
    telefono: "",
    pageWeb: "",
    direccion: "",
    horario: "",
    suscripcion: "",
    codPostal: "",
    ciudad: "",
    description: "",
    destacado: false,
  };

  constructor(
    private sanitizer: DomSanitizer,
    public toastController: ToastController,
    public alertController: AlertController,
    public firebaseauthService: FirebaseauthService,
    public firestorageService: FirestorageService,
    public firestoreService: FirestoreService,
    public auth: AngularFireAuth,
    public router: Router,
  ) {
    this.firebaseauthService.stateAuth().subscribe((res) => {
      if (res !== null) {
        this.uid = res.uid;
        this.usuario.uid = res.uid; // Asegura que el usuario.uid se actualice aquí también
        this.getUserInfo(this.uid);
      } else {
        console.log("no es cliente");
      }
    });
  }

  async ngOnInit() {
    this.usuario.uid = await this.firebaseauthService.getUid();
    console.log(this.usuario.uid);
    if (this.usuario.uid) {
      this.loadVideoUrl();
    } else {
      console.error('User ID is not available.');
      this.presentToast('User ID is not available.');
    }
  }

  loadVideoUrl(): void {
    if (!this.usuario.uid) {
      console.error('User ID is not available.');
      return;
    }
    const path = 'usuarios'; // Ajusta esto según tu estructura en Firestore
    this.firestoreService.getDoc(path, this.usuario.uid).subscribe((doc: any) => {
      if (doc && doc.videoInsta) {
        this.savedVideoUrl = doc.videoInsta;
      }
    });
  }

  saveVideo(): void {
    if (!this.usuario.uid) {
      console.error('User ID is not available.');
      this.presentToast('User ID is not available.');
      return;
    }
    const path = 'usuarios'; // Ajusta esto según tu estructura en Firestore
    const update = { videoInsta: this.videoUrl };

    this.firestoreService.updateDoc(update, path, this.usuario.uid).then(() => {
      this.savedVideoUrl = this.videoUrl;
      this.presentToast('Vídeo guardado con éxito');
    }).catch(error => {
      console.error('Error saving video URL:', error);
    });
  }

  async presentToast(message: string): Promise<void> {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000
    });
    toast.present();
  }

  getEmbedUrl(url: string): string {
    return `https://www.instagram.com/p/${this.extractVideoId(url)}/embed`;
  }

  extractVideoId(url: string): string {
    const match = url.match(/\/p\/(.*?)\//);
    return match ? match[1] : '';
  }

  getUserInfo(uid: string) {
    const path = "usuarios";
    this.suscriberUserInfo = this.firestoreService
      .getDoc<Usuario>(path, this.uid)
      .subscribe((res) => {
        console.log("get user info -> ", res);
        if (res) {
          this.usuario = res;
        }
      });
  }
}
