import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-mystore',
  templateUrl: './home-mystore.component.html',
  styleUrls: ['./home-mystore.component.scss'],
})
export class HomeMystoreComponent implements OnInit {

  constructor() { }

  ngOnInit() {}


  showmenu() {
    document.getElementById("otroModo").classList.toggle("show");
  }

  /* openDirecionApple() {
    const URLDireccion = 'https://apps.apple.com/fi/app/storing/id1576549308' 
    window.open(URLDireccion);
  }
  openDirecionAndroid() {
    const URLDireccion = 'https://play.google.com/store/apps/details?id=com.avaweb.storing&gl=ES' 
    window.open(URLDireccion);
  }
  openDirecionYoutube() {
    const URLDireccion = 'https://www.youtube.com/channel/UC1-a5Oj8W9AUaaIRGosJvuw' 
    window.open(URLDireccion);
  }
  openDirecionFacebook() {
    const URLDireccion = 'https://facebook.com/storingapp' 
    window.open(URLDireccion);
  }
  openDirecionInstagram() {
    const URLDireccion = 'https://www.instagram.com/storingapp/' 
    window.open(URLDireccion);
  }
  openDirecionLinkedin() {
    const URLDireccion = 'https://www.linkedin.com/company/storingapp/' 
    window.open(URLDireccion);
  }
  openDirecionTwitter() {
    const URLDireccion = 'https://twitter.com/storingapp' 
    window.open(URLDireccion);
  } */

}
