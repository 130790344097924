import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore.service';
import { Usuario } from '../../models';
import { FirebaseauthService } from '../../services/firebaseauth.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Subscription } from 'rxjs';
import { FirestorageService } from '../../services/firestorage.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { ModalController, ToastController, LoadingController } from '@ionic/angular';
import { CardRiderComponent } from 'src/app/componentes/card-rider/card-rider.component';

@Component({
  selector: 'app-riders',
  templateUrl: './riders.component.html',
  styleUrls: ['./riders.component.scss'],
})
export class RidersComponent implements OnInit {
  lat: number;
  lng: number;  
  admin = false;
  user = false;
  rider = false;
  shop = false;
  uid = '';
  suscriberUserInfo: Subscription;
  
  ingresarEnable = false;

  usuario: Usuario = {
    uid: '',
    nombre: '',
    responsable: '',
    apellido: '',
    email: '',
    password: '',
    referencia: '',
    foto: '',
    foto1: '',
    foto2: '',
    latitud: '',
    longitud: '',
    hash: '',
    qr: '',
    categoria: '',
    tipo: '',
    telefono: '',
    pageWeb: '',
    direccion: '',
    horario: '',
    suscripcion: '',
    codPostal: '',
    ciudad: '',
    description: '',
  };

  private path = 'Usuario/';
  usuarios: Usuario[] = [];
  filteredUsuarios: Usuario[] = [];
  comercioServicio = false;
  loading: HTMLIonLoadingElement;
  ordenado = false;
  comerciosProductosLoad: Usuario[] = [];
  comerciosServiciosLoad: Usuario[] = [];
  coordenadas: any;
  showBlocked = false;

  constructor(
    public firebaseauthService: FirebaseauthService,
    public firestorageService: FirestorageService, 
    public firestoreService: FirestoreService, 
    public auth: AngularFireAuth, 
    public router: Router, 
    public geolocation: Geolocation, 
    public modalController: ModalController,
    public toastController: ToastController,
    public loadingController: LoadingController
  ) {
    this.loadComerciosProductos();
    this.geolocal();
    this.firebaseauthService.stateAuth().subscribe(res => {
      if (res !== null) {
        this.uid = res.uid;
        this.getUserInfo(this.uid);
      } else {
        this.initUsuario();       
      }
    });
    this.getUid();
  }
  
  async ngOnInit() {
    const uid = await this.firebaseauthService.getUid();
    console.log(uid);
  }

  initUsuario() {
    this.uid = '';
    this.usuario = {
      uid: '',
      nombre: '',
      responsable: '',
      apellido: '',
      email: '',
      password: '',
      referencia: '',
      foto: '',
      foto1: '',
      foto2: '',
      latitud: '',
      longitud: '',
      qr: '',
      hash: '',
      categoria: '',
      tipo: '',
      pageWeb: '',
      telefono: '',
      direccion: '',
      horario: '',
      suscripcion: '',
      codPostal: '',
      ciudad: '',
      description: '',
    };
  }

  calcularMasCercano(comercios: Usuario[]): Promise<void> {
    return new Promise(async (resolve, reject) => {
      console.log('calcular mas cercano');
      if (!this.coordenadas) {
        this.coordenadas = await this.geolocation.getCurrentPosition();
      }
      if (this.coordenadas) {
        this.lat = this.coordenadas.coords.latitude;
        this.lng = this.coordenadas.coords.longitude;  
        comercios.forEach(cliente => {
          const latTienda = cliente.latitud;
          const longTienda = cliente.longitud;
          const R = 6371; // km
          let dLat = (latTienda - this.lat) as any;
          dLat = this.toRad(dLat)
          let dLon = (longTienda - this.lng) as any;
          dLon = this.toRad(dLon);
          const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                    Math.cos(this.toRad(this.lat)) * Math.cos(this.toRad(latTienda)) *
                    Math.sin(dLon / 2) * Math.sin(dLon / 2);
          const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
          const de = R * c;
          console.log('de ->', de);
          cliente.distancia = de;
        });
        comercios.sort((n1, n2) => {
          if (n1.distancia > n2.distancia) { return 1 }
          if (n1.distancia <= n2.distancia) { return -1 }
          return 0;
        });
      } else {
        console.log('no se puede obtener ubicación');
        this.presentToast('No podemos obtener tu ubicación');
      }
      resolve();
    });
  }

  toRad(grados: number) {
    return grados * Math.PI / 180;
  }

  loadComerciosProductos() { 
    if (!this.comerciosProductosLoad.length) {
      this.firestoreService.getClient<Usuario>(this.path).subscribe(async res => { 
        const destacados: Usuario[] = [];
        const noDestacados: Usuario[] = [];
        await this.presentLoading('ordenando por más cercanos...');
        res.forEach(comercio => {
          if (comercio.modo === 'rider') { // Filtro por modo
            if (comercio.destacado) {
              destacados.push(comercio);
            } else {
              noDestacados.push(comercio);
            }
          }
        });
        await this.calcularMasCercano(destacados);
        await this.calcularMasCercano(noDestacados);
        console.log('destacados -> ', destacados);
        console.log('noDestacados -> ', noDestacados);
        this.comerciosProductosLoad = [];
        this.comerciosProductosLoad.push(...destacados);
        this.comerciosProductosLoad.push(...noDestacados);
        this.usuarios = this.comerciosProductosLoad;
        this.filterUsers();
        this.comercioServicio = false;    
        this.dismissLoading();
      }); 
    } else {
      this.usuarios = this.comerciosProductosLoad;
      this.filterUsers();
      this.comercioServicio = false;  
    }
  }

  loadComerciosServicios() { 
    console.log('loadComerciosServicios() -> ', this.comerciosServiciosLoad); 
    if (!this.comerciosServiciosLoad.length) { 
      this.firestoreService.getServices<Usuario>(this.path).subscribe(async (res) => { 
        const destacados: Usuario[] = [];
        const noDestacados: Usuario[] = [];
        await this.presentLoading('ordenando por más cercanos...');
        res.forEach(comercio => {
          if (comercio.modo === 'rider') { // Filtro por modo
            if (comercio.destacado) {
              destacados.push(comercio);
            } else {
              noDestacados.push(comercio);
            }
          }
        });
        await this.calcularMasCercano(destacados);
        await this.calcularMasCercano(noDestacados);
        this.comerciosServiciosLoad = []; 
        this.comerciosServiciosLoad.push(...destacados);
        this.comerciosServiciosLoad.push(...noDestacados);  
        this.usuarios = this.comerciosServiciosLoad;
        this.filterUsers();
        this.comercioServicio = true;   
        this.dismissLoading();
      }); 
    } else {
      this.usuarios = this.comerciosServiciosLoad;
      this.filterUsers();
      this.comercioServicio = false;  
    }
  }

  getUserInfo(uid: string){
    const path = 'Usuario';
    this.suscriberUserInfo = this.firestoreService.getDoc<Usuario>(path, this.uid).subscribe(res => {
      this.usuario = res;
    });
  }

  getUid() {
    this.firebaseauthService.stateAuth().subscribe(res => {
      if (res !== null) {
        this.user = true;
        if (res.uid === 'm1fH1hpHvbNx4JMHJ7rwAEHnp0s1') { 
          this.admin = true;
        } else {
          this.admin = false;
        }
      } else {
        this.admin = false;
        this.user = false;
      }
    });
  }

  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      color: 'light',
    });
    toast.present();
  }

  async presentLoading(texto: string) {
    console.log('presentLoading');
    this.loading = await this.loadingController.create({
      message: texto,
      duration: 2000
    });
    await this.loading.present();
    return;
  }

  async dismissLoading() {
    if (this.loading) {
      console.log('dismissLoading()');
      await this.loading.dismiss();
    }
  }

  async geolocal() {
    const obtenerCoordenadas = await this.geolocation.getCurrentPosition();
    this.usuario.latitud = obtenerCoordenadas.coords.latitude;
    this.usuario.longitud = obtenerCoordenadas.coords.longitude;
    console.log(this.usuario.latitud, this.usuario.longitud);
  }

  async openCardRider(usuario: Usuario) {
    const modal = await this.modalController.create({
      component: CardRiderComponent,
      componentProps: {
        usuario: usuario,
        currentUserId: this.uid // Pasar el ID del usuario actual
      }
    });
    modal.onDidDismiss().then((data) => {
      if (data.data && data.data.saved) {
        this.loadComerciosProductos(); // Refrescar la lista si se guardaron cambios
      }
    });
    return await modal.present();
  }

  filterUsers() {
    if (this.showBlocked) {
      this.firestoreService.getDoc<Usuario>('Usuario', this.uid).subscribe(res => {
        const blockedUsers = res.blockedUsers || [];
        this.filteredUsuarios = this.usuarios.filter(usuario => blockedUsers.includes(usuario.uid));
      });
    } else {
      this.filteredUsuarios = this.usuarios;
    }
  }
}
