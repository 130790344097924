import { Component,  OnInit } from '@angular/core';




@Component({
  selector: 'app-mystore',
  templateUrl: './mystore.component.html',
  styleUrls: ['./mystore.component.scss'],
})
export class MystoreComponent implements OnInit {

  

  constructor() { }

  ngOnInit(){}

  showmenu() {
    document.getElementById("otroModo").classList.toggle("show");
  }

}