import { Component, OnInit } from '@angular/core';
import {Validators, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {FormularioService} from '../../services/formulario.service'
import {Message} from '../../models';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { FirestoreService } from 'src/app/services/firestore.service';
import { FirestorageService } from 'src/app/services/firestorage.service';


@Component({
  selector: 'app-ayuda-comercio',
  templateUrl: './ayuda-comercio.component.html',
  styleUrls: ['./ayuda-comercio.component.scss'],
})
export class AyudaComercioComponent implements OnInit {


  messages: Message[] = [];

  newMessage: Message;

 

  private path = 'Messages/';

  loading: any;


 
  constructor(public firestoreService: FirestoreService, public loadingController: LoadingController, public toastController: ToastController, public alertController: AlertController, public firestorageService: FirestorageService) { 
  
  }

  ngOnInit() {
    this.nuevo();
  }

  showmenu() {
    document.getElementById("otroModo").classList.toggle("show");
  }

  /* openDirecionApple() {
    const URLDireccion = 'https://apps.apple.com/fi/app/storing/id1576549308' 
    window.open(URLDireccion);
  }
  openDirecionAndroid() {
    const URLDireccion = 'https://play.google.com/store/apps/details?id=com.avaweb.storing&gl=ES' 
    window.open(URLDireccion);
  }
  openDirecionYoutube() {
    const URLDireccion = 'https://www.youtube.com/channel/UC1-a5Oj8W9AUaaIRGosJvuw' 
    window.open(URLDireccion);
  }
  openDirecionFacebook() {
    const URLDireccion = 'https://facebook.com/storingapp' 
    window.open(URLDireccion);
  }
  openDirecionInstagram() {
    const URLDireccion = 'https://www.instagram.com/storingapp/' 
    window.open(URLDireccion);
  }
  openDirecionLinkedin() {
    const URLDireccion = 'https://www.linkedin.com/company/storingapp/' 
    window.open(URLDireccion);
  }
  openDirecionTwitter() {
    const URLDireccion = 'https://twitter.com/storingapp' 
    window.open(URLDireccion);
  } */


  async guardarMessage(){
    this.presentLoading();
    const path = 'Messages';
    const name = this.newMessage.name;      
    this.firestoreService.createDoc(this.newMessage, this.path, this.newMessage.id).then( res=> {
    this.loading.dismiss();
    this.presentToast('enviado con exito');
    }).catch(error =>{
    this.presentToast('no se pudo enviar');
  });  
  }


  nuevo(){
    this.newMessage = {
      email: "",
      name: "",
      message: "",
      id: this.firestoreService.getId(),
      img: "",
      telefono: "",
      city: "",
      postCode: "",
      address: "",
      shop: "",
      telf: "",
      }; 
    }

    async presentLoading() {
      this.loading = await this.loadingController.create({
        cssClass: 'my-custom-class',
        message: 'guardando...',
      });
      await this.loading.present();  
    }

    async presentToast(msg: string) {
      const toast = await this.toastController.create({
        message: msg,
        duration: 2000,
        color: 'light',
      });
      toast.present();
    }
 

}
