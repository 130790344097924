// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  stripe: {
    testKey: 'pk_test_51I4914HzWu9oMw1CNT8zR2Dypknq2reDLqVTigWg8kUMyfv1RwOzatnKOhW1ChdnWAS70XT5t9pYnL08hRqRyrgE00c6CNQG2D'
  },
  firebaseConfig: {
  apiKey: "AIzaSyCnqpkpN_m-gJg6qHDqxNK9AetSToq_sp0",
  authDomain: "appstoring-e963a.firebaseapp.com",
  projectId: "appstoring-e963a",
  storageBucket: "appstoring-e963a.appspot.com",
  messagingSenderId: "681991386220",
  appId: "1:681991386220:web:4ece0a30b2a3d9fcb6d449",
  measurementId: "G-66X8THBXKR"  
  },


};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
