import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { RegistroComponent } from './registro/registro.component';
import { IonicModule } from '@ionic/angular';
import { LoginComponent } from './login/login.component';
import { RouterLink, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentesModule } from '../componentes/componentes.module';
import { StartComponent } from './start/start.component';
import { LupaComponent } from './lupa/lupa.component';
import { ContactoComponent } from './contacto/contacto.component';
import { AyudaComponent } from './ayuda/ayuda.component';
import { OptionsLoginComponent } from './options-login/options-login.component';
import { BackendModule } from '../backend/backend.module';
import { MystoreComponent } from './mystore/mystore.component';
import { LoginMystoreComponent } from './login-mystore/login-mystore.component';
import { HomeWebComponent } from './home-web/home-web.component';
import { FiltrosComponent } from './filtros/filtros.component';
import { FavoritosComponent } from './favoritos/favoritos.component';
import { PerfilComponent } from './perfil/perfil.component';
import { DestakComponent } from './destak/destak.component';
import { BackofficeClienteComponent } from './backoffice-cliente/backoffice-cliente.component';
import { HomeMystoreComponent } from './home-mystore/home-mystore.component';
import { MapComponent } from './map/map.component';
import { AyudaUsuarioComponent } from './ayuda-usuario/ayuda-usuario.component';
import { AyudaComercioComponent } from './ayuda-comercio/ayuda-comercio.component';
import { ComercioMystoreComponent } from './comercio-mystore/comercio-mystore.component';
import { SendEmailComponent } from './send-email/send-email.component';
import { CarritoComponent } from './carrito/carrito.component';
import { AyudaMystoreComponent } from './ayuda-mystore/ayuda-mystore.component';
import { ContactoMystoreComponent } from './contacto-mystore/contacto-mystore.component';
import { QuienesSomosComponent } from './quienes-somos/quienes-somos.component';
import { StarComponent } from './star/star.component';
import { InstaComponent } from './insta/insta.component';
import { OrderOnlineComponent } from './order-online/order-online.component';
import { ShopComponent } from './shop/shop.component';
import { OrdersComponent } from './orders/orders.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { OrderFavoritesComponent } from './order-favorites/order-favorites.component';
import { RidersComponent } from './riders/riders.component';
import { ShoppersComponent } from './shoppers/shoppers.component';
import { HomeUserComponent } from './home-user/home-user.component';




@NgModule({
  schemas: [ CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    HomeComponent,
    RegistroComponent,
    LoginComponent,
    StartComponent,
    LupaComponent,
    ContactoComponent,
    AyudaComponent,
    OptionsLoginComponent,
    MystoreComponent,
    LoginMystoreComponent,
    HomeWebComponent,
    FiltrosComponent,
    FavoritosComponent,
    PerfilComponent,
    DestakComponent,
    BackofficeClienteComponent,
    HomeMystoreComponent,
    MapComponent,
    AyudaUsuarioComponent,
    AyudaComercioComponent,
    ComercioMystoreComponent,
    SendEmailComponent,
    CarritoComponent,
    AyudaMystoreComponent,
    ContactoMystoreComponent,
    QuienesSomosComponent,
    StarComponent,
    InstaComponent,
    OrderOnlineComponent,
    ShopComponent,
    OrdersComponent,
    OrderDetailComponent,
    OrderFavoritesComponent,
    RidersComponent,
    ShoppersComponent,
    HomeUserComponent,
    RegistroComponent

  ],
  
  exports: [
    OptionsLoginComponent,
    HomeComponent,
    AyudaUsuarioComponent,
    AyudaComercioComponent,
    LoginComponent,
    OrdersComponent,
    OrderFavoritesComponent,
    ShopComponent,
    RegistroComponent
  ],


  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
    ComponentesModule,
    BackendModule,
    FormsModule,
    ReactiveFormsModule,


 
  ],

})
export class PagesModule { }
