import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../services/firestore.service';
import { Cliente } from '../../models';
import { FirebaseauthService } from '../../services/firebaseauth.service';
import { Geolocation, Geoposition } from '@ionic-native/geolocation/ngx';
import firebase from 'firebase/app';
import 'firebase/firestore';


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {

  
  
  lat: number;
  lng: number;

  geofire: any;

  admin = false;
  user = false;
  
  

  private path = 'Clientes/';

  clientes: Cliente[] = [];

  constructor(public firestoreService: FirestoreService, private firebaseauthService: FirebaseauthService, public geolocation: Geolocation) {
    this.loadClientes();
    
   
    
   }

  ngOnInit() {
 
  }

  


  
  loadClientes() { 

    this.firestoreService.getCollection<Cliente>(this.path).subscribe( res =>{ 
      if (this.lat !== null) {
        
        this.clientes = res;
        this.getUid();
        this.getUserUid();
      }else{
        console.log('no hay coordenadas');
      }      

      
    }); 
    }
    
  

  getUid() {
    this.firebaseauthService.stateAuth().subscribe( res => {
      if (res !== null) {
        if (res.uid === 'yNHV4zMO2EPoTwSCDh8kUcvjq612') { 
          this.admin = true;
      } else {
        this.admin = false;
      }
    } else {
      this.admin = false;
    }
    });
  }

  getUserUid() {
    this.firebaseauthService.stateAuth().subscribe( res => {
      if (res !== null) {
         
          this.user = true;
      } else {
        this.user = false;
      }
    
    });
  }


  


  }


