import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Usuario } from '../../models';
import { FirestoreService } from '../../services/firestore.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-card-rider',
  templateUrl: './card-rider.component.html',
  styleUrls: ['./card-rider.component.scss'],
})
export class CardRiderComponent implements OnInit {
  @Input() usuario: Usuario;
  @Input() currentUserId: string;
  usuarioActivo: boolean = false;

  constructor(private modalController: ModalController, private firestoreService: FirestoreService) {}

  ngOnInit() {
    this.checkIfUserIsBlocked();
  }

  async checkIfUserIsBlocked() {
    try {
      const userDoc = await this.firestoreService.getDoc<Usuario>('Usuario', this.currentUserId).pipe(first()).toPromise();
      this.usuarioActivo = userDoc?.blockedUsers?.includes(this.usuario.uid) ?? false;
      console.log(`User ${this.usuario.uid} blocked: ${this.usuarioActivo}`);
    } catch (error) {
      console.error('Error checking if user is blocked:', error);
    }
  }

  close() {
    this.modalController.dismiss();
  }

  async save() {
    try {
      if (this.usuarioActivo) {
        // Añadir el usuario a la lista de bloqueados
        await this.firestoreService.addBlockedUser(this.currentUserId, this.usuario.uid);
      } else {
        // Remover el usuario de la lista de bloqueados
        await this.firestoreService.removeBlockedUser(this.currentUserId, this.usuario.uid);
      }

      console.log('Estado actualizado correctamente');
    } catch (error) {
      console.error('Error al actualizar el estado:', error);
    }
  }
}
