import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Usuario } from 'src/app/models';
import { FirebaseauthService } from 'src/app/services/firebaseauth.service';
import { FirestorageService } from 'src/app/services/firestorage.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { Geolocation, Geoposition } from '@ionic-native/geolocation/ngx';


@Component({
  selector: 'app-login-mystore',
  templateUrl: './login-mystore.component.html',
  styleUrls: ['./login-mystore.component.scss'],
})
export class LoginMystoreComponent implements OnInit {



  usuario: Usuario = {
    uid: '',
    nombre: '',
    responsable: '',  
    apellido: '',
    email: '',
    password: '',
    referencia: '',
    foto: '',
    foto1: '',
    foto2: '',
    latitud: '',
    longitud: '',
    hash: '',
    qr:'',    
    categoria: '',
    tipo: '',  
    telefono: '',
    pageWeb: '',
    direccion: '',
    horario: '',
    suscripcion: '',
    codPostal: '',
    ciudad: '',
    description: '',  
  };
  
  newFile: any;
  uid = '';
  
  suscriberUserInfo: Subscription;
  
  ingresarEnable = false;
  
    constructor(public alertController: AlertController,public firebaseauthService: FirebaseauthService, public firestorageService: FirestorageService, public firestoreService: FirestoreService, public auth: AngularFireAuth, public router: Router, public geolocation: Geolocation, public modalController: ModalController) {
  
        this.geolocal();
        this.firebaseauthService.stateAuth().subscribe( res => {
          console.log(res);
          if (res !== null){
            this.uid = res.uid
            this.getUserInfo(this.uid);
          }else{
          this.initUsuario();       
          }
        });
  
     }
  
    async ngOnInit() {
     
      const uid = await this.firebaseauthService.getUid();
      console.log(uid);
  
    }
  
    initUsuario() {
            this.uid = '';
            this.usuario = {
              uid: '',
              nombre: '',
              responsable: '',
              qr:'',  
              apellido: '',
              email: '',
              password: '',
              referencia: '',
              foto: '',
              foto1: '',
              foto2: '',
              latitud: '',
              longitud: '',              
              hash: '',  
              categoria: '',
              tipo: '',  
              telefono: '',
              pageWeb: '',
              direccion: '',
              horario: '',
              suscripcion: '',
              codPostal: '',
              ciudad: '',
              description: '',
            };
  }
  
  
    async registrarse(){
      const credenciales = {
        email: this.usuario.email,
        password: this.usuario.password,
      };
      const res = await this.firebaseauthService.registrar(credenciales.email, credenciales.password).catch(err => {
       console.log('erro -> ', err);
       });
       const uid = await this.firebaseauthService.getUid();
       this.usuario.uid = uid;
       this.guardarUser();
      console.log(uid);
    }

  

  async guardarUser(){
    const path = 'Usuario';
    const name = this.usuario.nombre;  
    this.firestoreService.createDoc(this.usuario, path, this.usuario.uid).then( res=> {
    console.log('guardado con exito')
    this.router.navigateByUrl("/send-email");
  });  
  }
  
    async salir(){
      this.firebaseauthService.logout();
      this.suscriberUserInfo.unsubscribe();
      this.router.navigateByUrl("/home");
  }
  
    getUserInfo(uid: string){
      const path = 'Usuario';
      this.suscriberUserInfo = this.firestoreService.getDoc<Usuario>(path, this.uid).subscribe( res => {
        this.usuario = res;
        if(this.usuario.categoria !== 'servicio' && this.usuario.categoria !== 'producto'  ){
          this.presentAlert()
          return;
        }else{
          console.log('ir a backoof-cliente')
          this.suscriberUserInfo.unsubscribe();
         this.router.navigateByUrl("/backoffice-cliente");   
        } 
        
      })
    }
  
    ingresar(){
      const credenciales = {
        email: this.usuario.email,
        password: this.usuario.password,
      };
      this.firebaseauthService.login(credenciales.email, credenciales.password). then( res => {        
        console.log('ingresado con exito')        
     
        }
      )
    }

    async presentAlert() {
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: 'Área reservada para comercios registrados',
        /* subHeader: 'Subtitle',
        message: 'This is an alert message.', */
        buttons: ['OK']
      });
  
      await alert.present();
  
      const { role } = await alert.onDidDismiss();
      console.log('onDidDismiss resolved with role', role);
    }
  

   
    
  
    async newImageUpload(event: any){
  
      if (event.target.files && event.target.files[0]) {
        this.newFile = event.target.files[0]; 
        const reader = new FileReader();
        reader.onload = ((image) => {
          this.usuario.foto = image.target.result as string;
        });
        reader.readAsDataURL(event.target.files[0]);
      } 
    }
  
    async geolocal(){
      const obtenerCoordenadas = await this.geolocation.getCurrentPosition();
      this.usuario.latitud = obtenerCoordenadas.coords.latitude;
      this.usuario.longitud = obtenerCoordenadas.coords.longitude;
        console.log(this.usuario.latitud, this.usuario.longitud)
      };
  
     
    }