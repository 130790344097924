import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MenuItem } from '../../models';
import { FirestoreService } from '../../services/firestore.service';
import { ModalController } from '@ionic/angular';
import { EditProductModalComponent } from '../edit-product-modal/edit-product-modal.component';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit, OnChanges {
  @Input() categories: string[] = [];
  @Input() products: MenuItem[] = [];
  @Output() productEdit = new EventEmitter<MenuItem>();
  @Output() productDeleted = new EventEmitter<void>();

  filteredProducts: MenuItem[] = [];
  selectedCategory: string = '';

  constructor(private firestoreService: FirestoreService, private modalController: ModalController) {}

  ngOnInit() {
    if (this.categories.length > 0) {
      this.selectedCategory = this.categories[0];
      this.filterProductsByCategory(this.selectedCategory);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.products) {
      this.filterProductsByCategory(this.selectedCategory);
    }
    if (changes.categories && !this.selectedCategory && this.categories.length > 0) {
      this.selectedCategory = this.categories[0];
      this.filterProductsByCategory(this.selectedCategory);
    }
  }

  filterProductsByCategory(category: string) {
    this.selectedCategory = category;
    if (category) {
      this.filteredProducts = this.products.filter(product => product.category === category);
    } else {
      this.filteredProducts = [...this.products];
    }
  }

  async editProduct(product: MenuItem) {
    const modal = await this.modalController.create({
      component: EditProductModalComponent,
      componentProps: {
        product: product,
        uid: product.uid
      }
    });

    modal.onDidDismiss().then((result) => {
      if (result.data) {
        this.productEdit.emit(result.data);
      }
    });

    await modal.present();
  }

  async deleteProduct(product: MenuItem) {
    try {
      await this.firestoreService.deleteMenuItem(product.uid, product.id);
      console.log(`Producto eliminado: ${product.id}`);
      this.products = this.products.filter(p => p.id !== product.id);
      this.filterProductsByCategory(this.selectedCategory);
      this.productDeleted.emit();
    } catch (error) {
      console.error('Error al eliminar el producto:', error);
    }
  }
}
