import { Component, Input, OnInit } from '@angular/core';
import { Cliente, Usuario } from '../../models'
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { CardComponent } from '../card/card.component';
import { Router } from '@angular/router';
import { FirestoreService } from 'src/app/services/firestore.service';
import { FirebaseauthService } from 'src/app/services/firebaseauth.service';
import { CarritoService } from 'src/app/services/carrito.service';
import { CardclienteComponent } from '../cardcliente/cardcliente.component';



@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.scss'],
})
export class ClientesComponent implements OnInit {

  @Input()usuario: Usuario;


  user = false;



  constructor(public carritoService: CarritoService, public modalController: ModalController, public alertController: AlertController, public router: Router, public firestoreService: FirestoreService, private firebaseauthService: FirebaseauthService, public toastController: ToastController,) {
    this.getUserUid()
  }

   ngOnInit() {}

   

  

async openModal(){
  const modal = await this.modalController.create({
    component: CardclienteComponent,
    componentProps: {cliente: this.usuario}
  });
  return await modal.present();
}

async addFavorite() {
  
  if(this.user !== true) {
    const alert = await this.alertController.create({
      cssClass: 'body',
      header: 'Necesitas iniciarr sesión para guardar tus comercios favoritos',
      message: '¿Te llevamos al login?',
      buttons: [
        {
          text: 'no',
          role: 'cancel',
          cssClass: '',
          handler: (blah) => {
          }
        }, {
          text: 'sí',
          handler: () => {   
            this.router.navigate(['login']);
          }
        }
      ]
    });
    
    await alert.present();
    }else {
      this.carritoService.addCliente(this.usuario);
      this.presentToast('Añadido a favorito con exito');

    }
};


async presentToast(msg: string) {
  const toast = await this.toastController.create({
    message: msg,
    duration: 2000,
    color: 'light',
  });
  toast.present();
}


getUserUid() {
  this.firebaseauthService.stateAuth().subscribe( res => {
    if (res !== null) {
       
        this.user = true;
    } else {
      this.user = false;
    }
  
  });
}


}