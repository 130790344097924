import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import {  RouteReuseStrategy } from '@angular/router';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PagesModule } from './pages/pages.module';
import { BackendModule } from './backend/backend.module';
import { AngularFireModule } from '@angular/fire';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { PipesModule } from './pipes/pipes.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FormularioService } from './services/formulario.service';
import { PayPal} from '@ionic-native/paypal/ngx';
import { HttpClientModule } from '@angular/common/http';

import '@codetrix-studio/capacitor-google-auth';
import { registerWebPlugin } from '@capacitor/core';
import { FacebookLogin } from '@rdlabo/capacitor-facebook-login';
import { MyHammerConfig } from 'src/hammer.config';
import { OrderFavoritesService } from './services/order-favorites.service';





registerWebPlugin(FacebookLogin);


@NgModule({
  schemas: [ CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent],
  entryComponents: [],
  imports: [FormsModule, BrowserModule,  ReactiveFormsModule, IonicModule.forRoot(), AppRoutingModule, BackendModule, PagesModule, AngularFireModule.initializeApp(environment.firebaseConfig), AngularFirestoreModule.enablePersistence(), AngularFireStorageModule, AngularFireAuthModule,
    PipesModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }), HammerModule, HttpClientModule // Asegúrate de importar HammerModule
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    PayPal,
    StatusBar,
    FormularioService,
    OrderFavoritesService,
    SplashScreen,
    Geolocation,
    { provide: RouteReuseStrategy,  useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
