import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent {
  @Input() videoUrl: string;

  constructor(private sanitizer: DomSanitizer) {}

  isShort(): boolean {
    return this.videoUrl.includes('shorts/');
  }

  isYouTube(): boolean {
    return this.videoUrl.includes('youtube.com') || this.videoUrl.includes('youtu.be');
  }

  isInstagram(): boolean {
    return this.videoUrl.includes('instagram.com');
  }

  isInstagramPost(): boolean {
    return this.videoUrl.includes('/p/');
  }

  isInstagramReel(): boolean {
    return this.videoUrl.includes('/reels/');
  }

  getContainerStyle(): any {
    if (this.isInstagram()) {
      return {
        'width': '100%',
        'height': 'auto',
        'position': 'relative'
      };
    }
    return {
      'width': this.isShort() ? '70%' : '100%',
      'position': 'relative'
    };
  }

  sanitizedVideoUrl(): SafeResourceUrl {
    let url = this.videoUrl;
    let embedUrl = '';

    if (this.isInstagram()) {
      if (this.isInstagramPost()) {
        const videoId = url.split('/p/')[1].split('/')[0];
        embedUrl = `https://www.instagram.com/p/${videoId}/embed`;
      } else if (this.isInstagramReel()) {
        const videoId = url.split('/reels/')[1].split('/')[0];
        embedUrl = `https://www.instagram.com/reel/${videoId}/embed`;
      }
    } else if (this.isYouTube()) {
      if (this.isShort()) {
        const videoId = url.split('shorts/')[1].split(/[?&]/)[0];
        embedUrl = `https://www.youtube.com/embed/${videoId}`;
      } else {
        const videoIdMatch = url.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/);
        const videoId = videoIdMatch ? videoIdMatch[1] : url;
        embedUrl = `https://www.youtube.com/embed/${videoId}`;
      }
    }

    return this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
  }
}
