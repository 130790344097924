import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirestoreService } from './firestore.service';
import { Usuario } from '../models';
import firebase from 'firebase/app';
import { Router } from '@angular/router';

import { Plugins } from '@capacitor/core';
import { FacebookLoginResponse } from '@rdlabo/capacitor-facebook-login';
import { Platform } from '@ionic/angular';
const { FacebookLogin, SignInWithApple } = Plugins;


@Injectable({
  providedIn: 'root',
})
export class FirebaseauthService {
  datosUsuario: Usuario;
  private fbPlugin: any; 

  constructor(public auth: AngularFireAuth,
              private firestoreService: FirestoreService,
              public router: Router,
              public platform: Platform) {

      this.stateUser();
      this.setupFbLogin();
      this.auth.getRedirectResult().then(async (res) => {
        console.log('getRedirectResult() -> ', res);
        if (res.user) {
          const datos: any = {
            displayName: res.user.displayName,
            uid: res.user.uid,
            photoURL: res.user.photoURL,
          };
          this.updateUserInfo(true, datos);
        }
      });
  }

  stateUser() {
    this.stateAuth().subscribe((res) => {
      if (res !== null) {
        console.log(res.uid);
        this.getInfoUser(res);
      }
    });
  }
  login(email: string, password: string) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  async deleteUser() {
    var user = await this.auth.currentUser;

    user
      .delete()
      .then(function () {
        // User deleted.
      })
      .catch(function (error) {
        // An error happened.
      });
  }

  logout() {
    return this.auth.signOut();
  }

  registrar(email: string, password: string) {
    return this.auth
      .createUserWithEmailAndPassword(email, password)
      .then(() => {
        this.verificarEmail();
      });
  }

  async observer() {
    this.auth.onAuthStateChanged((user) => {
      if (user) {
        var uid = user.uid;
      } else {
      }
    });
  }

  async getUid() {
    const user = await this.auth.currentUser;
    if (user === null) {
      return null;
    } else {
      return user.uid;
    }
  }

  stateAuth() {
    return this.auth.authState;
  }

  async verificarEmail() {
    var user = await this.auth.currentUser;
    user
      .sendEmailVerification()
      .then(function () {
        // Email sent.
      })
      .catch(function (error) {
        // An error happened.
      });
  }

  async getInfoUser(user: any) {
    const uid = await this.getUid();
    const path = 'Usuario';
    this.firestoreService.getDoc<Usuario>(path, uid).subscribe((res) => {
      console.log('getuser info -> ', res);
      if (res !== undefined) {
        this.datosUsuario = res;
      } else {
        this.updateUserInfo(false, user);
      }
    });
  }

  updateUserInfo(update: boolean, user: any) {

    return new Promise( async (resolve, reject) => {
        const path = 'Usuario';
        const datos = {
          nombre: user.displayName,
          foto: user.photoURL,
          uid: user.uid,
        };
        console.log('update user info -> ', datos);
        if (update) {
         await this.firestoreService.updateDoc(datos, path, user.uid).catch(() => {
            console.log('erro to update user -> creat new')
            this.firestoreService.createDoc(datos, path, user.uid);
            resolve(true);
          });
          resolve(true);
        } else {
          await this.firestoreService.createDoc(datos, path, user.uid);
          resolve(true);
        }
      
    });
  }

  // LOGIN WITH GOOGLE
  async loginWithGoogle() {
    Plugins.GoogleAuth.signIn()
      .then((googleUser: any) => {
        this.onLoginSuccess(
          googleUser.authentication.idToken,
          googleUser.authentication.accessToken
        );
      })
      .catch(() => {});
  }

  onLoginSuccess(accessToken: any, accessSecret: any) {
    const credential = accessSecret
      ? firebase.auth.GoogleAuthProvider.credential(accessToken, accessSecret)
      : firebase.auth.GoogleAuthProvider.credential(accessToken);
    this.auth
      .signInWithCredential(credential)
      .then((success) => {
        console.log('success -> ', success);
        this.router.navigateByUrl("/home");
      })
      .catch((err) => {
        console.log('error  -> ', err);
      });
  }

  // LOGIN CON FACEBOOK
  async setupFbLogin() {
    if ( !this.platform.is('capacitor')) {
        console.log('setupFbLogin() -> is browser')
        const { FacebookLogin } = Plugins;
        this.fbPlugin = FacebookLogin;
        console.log('this.fbPlugin -> ', this.fbPlugin);
    } else {
        console.log('setupFbLogin() -> is native')
        this.fbPlugin = FacebookLogin;
        console.log('this.fbPlugin -> ', this.fbPlugin);
    }
  }

  async loginWithFacebook() {
    try {
        console.log('loginWithFacebook() -> this.fbPlugin -> ', this.fbPlugin);
        const FACEBOOK_PERMISSIONS = ['email', 'public_profile'] ;  // 'user_birthday', 'user_photos', 'user_gender'
        const result = (await this.fbPlugin.login({permissions: FACEBOOK_PERMISSIONS}))  as FacebookLoginResponse;
        console.log('result login -> ', result);
        const credential_fb = firebase.auth.FacebookAuthProvider.credential(result.accessToken.token);
        this.auth.signInWithCredential(credential_fb).then( success => {
            console.log('loginWithFacebook() -> ', success);
              const datos: any= {
                  email: success.user.email,
                  displayName: null,
                  photoURL: null,
                  uid: success.user.uid,
              };
              if (success.user.photoURL) {
                datos.photoURL = success.user.photoURL;
              }
              if (success.user.displayName) {
                datos.displayName = success.user.displayName;
              }
              this.router.navigateByUrl("/home");
              this.updateUserInfo(true, datos);
              console.log('success -> ', success);
        }).catch( err => {
          console.log('error -> ', err);;
        })
    } catch (error) {
      console.log('error -> ', error);;
    }
  }

  // LOGIN WITH APPLE 
  async signInWithApple() {
    console.log('SignInWithApple -> ', SignInWithApple);
    SignInWithApple.Authorize().then( (res: any) => {
      console.log('signInWithApple() -> ', res.response)
      this.creatUserWithAppleID(res.response);
    }).catch( (response: any) => {
      console.error('signInWithApple() -> ', response)
    })
  }

  async creatUserWithAppleID(responde: ResponseAppleLogin) {
    const provider = new firebase.auth.OAuthProvider('apple.com');
    const credential = provider.credential({idToken: responde.identityToken});
    console.log('After sing in -> ', credential);
    this.auth.signInWithCredential(credential)
    .then( async (success) => {
          const datos: any= {
            email: responde.email,
            displayName: null,
            photoURL: null,
            uid: success.user.uid,
        };
        if (success.user.photoURL) {
          datos.photoURL = success.user.photoURL;
        }
        if (responde.givenName) {
            datos.displayName =  responde.givenName + ' ' + responde.familyName;
        }
        console.log('datos -> ', datos);
        console.log('success -> ', success);
        await this.updateUserInfo(true, datos);
        this.router.navigateByUrl("/home");
    }).catch( (error) => {
        console.log(' error signInWithApple() -> ', error)
    })
  }

}


interface ResponseAppleLogin {
  email: string;
  identityToken: string;
  familyName: string;
  user: string;
  givenName: string;
  authorizationCode: string;
}