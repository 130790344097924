import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Usuario } from 'src/app/models';
import { FirebaseauthService } from 'src/app/services/firebaseauth.service';
import { FirestorageService } from 'src/app/services/firestorage.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { Geolocation} from '@ionic-native/geolocation/ngx';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss'],
})
export class RegistroComponent implements OnInit {
  showAvatars = false;
  avatars = [
    'assets/avatars/avatar1.png',
    'assets/avatars/avatar2.png',
    'assets/avatars/avatar3.png',
    'assets/avatars/avatar4.png',
    'assets/avatars/avatar5.png',
    'assets/avatars/avatar6.png',
    'assets/avatars/avatar7.png',
    'assets/avatars/avatar8.png',
    'assets/avatars/avatar9.png',
    'assets/avatars/avatar10.png',
  ];
  
  usuario: Usuario = this.initUsuario();

  register = false;
  uid = '';
  suscriberUserInfo: Subscription;

  constructor(
    private location: Location, 
    public toastController: ToastController, 
    public alertController: AlertController, 
    public firebaseauthService: FirebaseauthService, 
    public firestorageService: FirestorageService, 
    public firestoreService: FirestoreService, 
    public auth: AngularFireAuth, 
    public router: Router, 
    public geolocation: Geolocation, 
    public modalController: ModalController
  ) { 
    this.geolocal();
    this.firebaseauthService.stateAuth().subscribe(res => {
      console.log(res);
      if (res !== null){
        this.uid = res.uid
        this.getUserInfo(this.uid);
      } else {
        this.initUsuario();       
      }
    });
  }

  async ngOnInit() {
    this.initializeRotatingImage();
    this.initUsuario();
    const uid = await this.firebaseauthService.getUid();
    console.log(uid);
  }

  initUsuario(): Usuario {
    return {
      uid: '',
      nombre: '',
      responsable: '',
      apellido: '',
      email: '',
      password: '',
      referencia: '',
      foto: '',
      foto1: '',
      foto2: '',
      latitud: '',
      longitud: '',
      hash: '',
      qr:'',
      categoria: '',
      tipo: '',
      telefono: '',
      pageWeb: '',
      direccion: '',
      horario: '',
      suscripcion: '',
      codPostal: '',
      ciudad: '',
      description: '',
    };
  }

  async registrarse(){
    const credenciales = {
      email: this.usuario.email,
      password: this.usuario.password,
    };
    const res = await this.firebaseauthService.registrar(credenciales.email, credenciales.password).catch(err => {
     console.log('erro -> ', err);
     });
     const uid = await this.firebaseauthService.getUid();     
     this.usuario.uid = uid;
     this.router.navigateByUrl("/home");
     this.guardarUser();
     this.sendEmail()
    console.log(uid);

    var user = firebase.auth().currentUser;

    if (user != null) {
      user.providerData.forEach(function (profile) {
        console.log("Sign-in provider: " + profile.providerId);
        console.log("  Provider-specific UID: " + profile.uid);
        console.log("  Name: " + profile.displayName);
        console.log("  Email: " + profile.email);
        console.log("  Photo URL: " + profile.photoURL);
      });
    }
  }

  goToHome() {
    this.router.navigate(['/home']); 
  }
  goBackToLogin() {
    this.register = false;
  }

  optionsLogin() {
    this.register = false;
    this.router.navigate(['/options-login']);
  }
 
  toggleAvatarSelection() {
    this.showAvatars = !this.showAvatars;
  }

  selectAvatar(avatar: string) {
    this.usuario.foto = avatar;
    this.showAvatars = false;
  }

  triggerFileUpload() {
    const fileUpload = document.getElementById('file-upload') as HTMLInputElement;
    fileUpload.click();
  }

  newImageUpload(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.usuario.foto = e.target.result;
    };
    reader.readAsDataURL(file);
  }

  async guardarUser(){
    const path = 'Usuario';
    const name = this.usuario.nombre;  
    this.firestoreService.createDoc(this.usuario, path, this.usuario.uid).then( res=> {
    console.log('registrado con exito')
    this.presentToast('guardado con exito');
  });  
  }
  
  async sendEmail(){    
    const uid = await this.firebaseauthService.verificarEmail(); 
  }

  getUserInfo(uid: string){
    const path = 'Usuario';
    this.suscriberUserInfo = this.firestoreService.getDoc<Usuario>(path, this.uid).subscribe( res => {
      this.usuario = res;
    })
  }

  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      color: 'light',
    });
    toast.present();
  }

  async geolocal(){
    const obtenerCoordenadas = await this.geolocation.getCurrentPosition();
    this.usuario.latitud = obtenerCoordenadas.coords.latitude;
    this.usuario.longitud = obtenerCoordenadas.coords.longitude;
    console.log(this.usuario.latitud, this.usuario.longitud)
  }

  initializeRotatingImage() {
    const rotatingImage = document.getElementById('rotatingImage');

    if (rotatingImage) {
      // Establecer la duración de la animación inicial
      let animationDuration = 5; // 5 segundos
      rotatingImage.style.animationDuration = `${animationDuration}s`;

      // Debug: Comprobar si se registra el evento
      rotatingImage.addEventListener('click', () => {
        console.log('Image clicked');
      });
}
  }
}
