import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import {Message} from '../models';


@Injectable({
  providedIn: 'root'
})

export class FormularioService {
private contactCollection: AngularFirestoreCollection<Message>;

  constructor(private afs: AngularFirestore) {
    this.contactCollection = afs.collection<Message>('contacts');
   }

   saveMessage(newContact: Message): void {
     this.contactCollection.add(newContact);
   }
}
