import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { Cliente, Reporte, Usuario } from "../../models";
import { FirebaseauthService } from "../../services/firebaseauth.service";
import { FirestorageService } from "../../services/firestorage.service";
import { FirestoreService } from "../../services/firestore.service";
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import {
  AlertController,
  ModalController,
  ToastController,
} from "@ionic/angular";
import {
  PayPal,
  PayPalPayment,
  PayPalConfiguration,
  PayPalPaymentDetails,
} from "@ionic-native/paypal/ngx";
import { QrComponent } from "src/app/componentes/qr/qr.component";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

declare var paypal: any;

@Component({
  selector: "app-backoffice-cliente",
  templateUrl: "./backoffice-cliente.component.html",
  styleUrls: ["./backoffice-cliente.component.scss"],
})
export class BackofficeClienteComponent implements OnInit {
  selectedBasicSubscription = false;
  selectedPremiumSubscription = false;

  tipoDeSubscription = "basic";
  reportes: Reporte[] = [];
  numReportes = 2;
  suscriber: any;
  enableMore = true;

  usuario: Usuario = {
    uid: "",
    video: '',
    nombre: "",
    responsable: "",
    apellido: "",
    email: "",
    password: "",
    referencia: "",
    foto: "",
    foto1: "",
    qr: "",
    foto2: "",
    latitud: "",
    longitud: "",
    hash: "",
    categoria: "",
    tipo: "",
    telefono: "",
    pageWeb: "",
    direccion: "",
    horario: "",
    suscripcion: "",
    codPostal: "",
    ciudad: "",
    description: "",
    destacado: false,
  };

  newFile: "";
  newFile1: "";
  newFile2: "";
  newFileQr: "";
  uid = "";

  suscriberUserInfo: Subscription;

  ingresarEnable = false;

  enableNewCliente = false;

  destacarStar = false;

  constructor(
    private sanitizer: DomSanitizer,
    public toastController: ToastController,
    public alertController: AlertController,
    public firebaseauthService: FirebaseauthService,
    public firestorageService: FirestorageService,
    public firestoreService: FirestoreService,
    public auth: AngularFireAuth,
    public router: Router,
    public geolocation: Geolocation,
    public modalController: ModalController,
    private payPal: PayPal
  ) {
    this.firebaseauthService.stateAuth().subscribe((res) => {
      console.log(res);
      if (res !== null) {
        this.uid = res.uid;
        this.getUserInfo(this.uid);
      } else {
        console.log("no es cliente");
      }
    });
  }

  async ngOnInit() {
    const uid = await this.firebaseauthService.getUid();
    console.log(uid);
  }

  customAlertOptionsBasic: any = {
    header: "Tipo de Subscripción",
    subHeader: "Basic",
    message: "mensual / anual",
    translucent: true,
  };

  customAlertOptionsPremium: any = {
    header: "Tipo de Subscripción",
    subHeader: "Premium",
    message: "mensual / anual",
    translucent: true,
  };

  async salir() {
    this.suscriberUserInfo.unsubscribe();
    await this.firebaseauthService.logout();
    //  this.router.navigateByUrl("/login-mystore");
    this.router.navigate(["/home-mystore"]);
  }


  getUserInfo(uid: string) {
    const path = "Usuario";
    this.suscriberUserInfo = this.firestoreService
      .getDoc<Usuario>(path, this.uid)
      .subscribe((res) => {
        console.log("get user info -> ", res);
        if (res) {
          this.usuario = res;
          this.getReportes(this.usuario.uid);
          if (res.suscripcion) {
            this.tipoDeSubscription = res.suscripcion;
          }
        }
      });
  }

  guardarVideo() {
    if (this.usuario.video) {
      const path = 'Usuario';
      const update = { video: this.usuario.video };
      this.firestoreService.updateDoc(update, path, this.usuario.uid).then(() => {
        this.presentToast('Vídeo guardado con éxito');
      }).catch(error => {
        this.presentToast('Error al guardar vídeo');
        console.error('Error al guardar vídeo:', error);
      });
    }
  }

  isShort(): boolean {
    return this.usuario.video.includes('shorts/');
  }

  isYouTube(): boolean {
    return this.usuario.video.includes('youtube.com') || this.usuario.video.includes('youtu.be');
  }

  isInstagram(): boolean {
    return this.usuario.video.includes('instagram.com');
  }

  isInstagramPost(): boolean {
    return this.usuario.video.includes('/p/');
  }

  isInstagramReel(): boolean {
    return this.usuario.video.includes('/reel/') || this.usuario.video.includes('/reels/');
  }
  

  getContainerStyle(): any {
    if (this.isInstagram()) {
      return {
        'width': '100%',
        'height': 'auto',
        'position': 'relative',
        'padding-top': '120%' /* Ajustar para Instagram */
      };
    }
    return {
      'width': '100%',
      'position': 'relative',
      'padding-top': this.isShort() ? '100%' : '56.25%' /* Ajustar para Shorts y videos normales */
    };
  }
  
  
  

  sanitizedVideoUrl(): SafeResourceUrl {
    let url = this.usuario.video;
    let embedUrl = '';
  
    if (this.isInstagram()) {
      if (this.isInstagramPost()) {
        const videoId = url.split('/p/')[1].split('/')[0];
        embedUrl = `https://www.instagram.com/p/${videoId}/embed`;
      } else if (this.isInstagramReel()) {
        const videoId = url.split(/\/reel\/|\/reels\//)[1].split('/')[0];
        embedUrl = `https://www.instagram.com/reel/${videoId}/embed`;
      }
    } else if (this.isYouTube()) {
      if (this.isShort()) {
        const videoId = url.split('shorts/')[1].split(/[?&]/)[0];
        embedUrl = `https://www.youtube.com/embed/${videoId}`;
      } else {
        const videoIdMatch = url.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/);
        const videoId = videoIdMatch ? videoIdMatch[1] : url;
        embedUrl = `https://www.youtube.com/embed/${videoId}`;
      }
    }
  
    return this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
  }
  

  getReportes(uid: string = this.usuario.uid) {
    const path = "Usuario/" + uid + "/reportes/";
    let startAt = null;

    if (this.reportes.length) {
      startAt = this.reportes[this.reportes.length - 1].fecha;
    }
    this.suscriber = this.firestoreService
      .getCollectionPaginada<Reporte>(path, this.numReportes, startAt)
      .subscribe((res) => {
        if (res.length < this.numReportes) {
          this.enableMore = false;
        }
        if (res.length) {
          res.forEach((comentario) => {
            let exist = this.reportes.find((commentExist, index) => {
              if (commentExist.id === comentario.id) {
                this.reportes[index] = comentario;
              }
              return commentExist.id === comentario.id;
            });
            if (exist === undefined) {
              this.reportes.push(comentario);
            }
          });
          console.log(res);
        }
      });
  }

  //  let nameImg = this.newReporte.nombre + '_reporte_' + id;

  descargarReporte(reporte: Reporte) {}

  async changeBasicSubscription(event) {
    if (event.detail.value == "true") {
      console.log(event.detail.value);
      this.selectedBasicSubscription = true;
    } else {
      console.log(event.detail.value);
      this.selectedBasicSubscription = false;
    }
  }

  async changePremiumSubscription(event) {
    if (event.detail.value == "true") {
      console.log(event.detail.value);
      this.selectedPremiumSubscription = true;
    } else {
      console.log(event.detail.value);
      this.selectedPremiumSubscription = false;
    }
  }

  initUsuario() {
    this.uid = "";
    this.usuario = {
      uid: "",
      responsable: "",
      nombre: "",
      apellido: "",
      email: "",
      password: "",
      referencia: "",
      foto: "",
      foto1: "",
      foto2: "",
      latitud: "",
      longitud: "",
      qr: "",
      hash: "",
      categoria: "",
      tipo: "",
      telefono: "",
      pageWeb: "",
      direccion: "",
      horario: "",
      suscripcion: "",
      codPostal: "",
      ciudad: "",
      description: "",
      destacado: false,
    };
  }

  async newImageUpload(event: any, opc: number) {
    console.log("opc", opc);
  
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (image) => {
        let previewElement: HTMLImageElement;
        switch (opc) {
          case 0:
            this.newFile = event.target.files[0];
            console.log("opc foto principal");
            this.usuario.foto = image.target.result as string;
            previewElement = document.getElementById('preview-main') as HTMLImageElement;
            break;
          case 1:
            this.newFile1 = event.target.files[0];
            console.log("opc foto 1");
            this.usuario.foto1 = image.target.result as string;
            previewElement = document.getElementById('preview-1') as HTMLImageElement;
            break;
          case 2:
            this.newFile2 = event.target.files[0];
            console.log("opc foto 2");
            this.usuario.foto2 = image.target.result as string;
            previewElement = document.getElementById('preview-2') as HTMLImageElement;
            break;
          default:
            console.log("Opción no válida");
            return;
        }
        if (previewElement) {
          previewElement.src = image.target.result as string;
          previewElement.style.display = 'block';
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }
  
  
  

  async openModal() {
    const modal = await this.modalController.create({
      component: QrComponent,
      componentProps: { usuario: this.usuario },
    });
    return await modal.present();
  }

  async guardarUser() {
    const path = "Usuario";
    const pathImg = "Productos";

    let nameImg = this.usuario.nombre + "_portada";
    if (this.newFile !== undefined) {
      if (this.newFile.length) {
        const res = await this.firestorageService.uploadImage(
          this.newFile,
          pathImg,
          nameImg
        );
        this.usuario.foto = res;
      }
    }
    nameImg = this.usuario.nombre + "_qr";
    if (this.newFileQr !== undefined) {
      if (this.newFileQr.length) {
        const res = await this.firestorageService.uploadImage(
          this.newFileQr,
          path,
          nameImg
        );
        this.usuario.qr = res;
      }
    }
    console.log("this.usuarios ->", this.usuario);
    this.firestoreService
      .createDoc(this.usuario, path, this.usuario.uid)
      .then((res) => {
        console.log("guardado con exito");
        this.presentToast("guardado con exito");
      });
  }

  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      color: "light",
    });
    toast.present();
  }

  destaque(event: boolean) {
    this.destacarStar = event;
  }

  aplicarDestaque() {
    const path = "Usuario";
    const updateDoc = {
      destacado: this.destacarStar,
    };
    if (this.destacarStar && !this.usuario.destacado) {
      console.log("aplicar como destacado");
      return this.firestoreService
        .updateDoc(updateDoc, path, this.uid)
        .then(() => {
          this.presentToast("Destaque aplicado con éxito");
        })
        .catch(() => {
          this.presentToast("No se pudo aplicar el destaque");
        });
    }
    if (!this.destacarStar && this.usuario.destacado) {
      console.log("quitar como destacado");
      return this.firestoreService
        .updateDoc(updateDoc, path, this.uid)
        .then(() => {
          this.presentToast("Quitar destaque con éxito");
        })
        .catch(() => {
          this.presentToast("No se pudo quitar el destaque");
        });
    }
  }

  openDirecionApple() {
    const URLDireccion = "https://apps.apple.com/fi/app/storing/id1576549308";
    window.open(URLDireccion);
  }
  openDirecionAndroid() {
    const URLDireccion =
      "https://play.google.com/store/apps/details?id=com.avaweb.storing&gl=ES";
    window.open(URLDireccion);
  }
  openDirecionYoutube() {
    const URLDireccion =
      "https://www.youtube.com/channel/UC1-a5Oj8W9AUaaIRGosJvuw";
    window.open(URLDireccion);
  }
  openDirecionFacebook() {
    const URLDireccion = "https://facebook.com/storingapp";
    window.open(URLDireccion);
  }
  openDirecionInstagram() {
    const URLDireccion = "https://www.instagram.com/storingapp/";
    window.open(URLDireccion);
  }
  openDirecionLinkedin() {
    const URLDireccion = "https://www.linkedin.com/company/storingapp/";
    window.open(URLDireccion);
  }
  openDirecionTwitter() {
    const URLDireccion = "https://twitter.com/storingapp";
    window.open(URLDireccion);
  }
}
