import { resolveForwardRef } from '@angular/compiler/src/util';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ToastController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { Cliente, Favorito, Usuario, ClienteFavorito } from '../models';
import { FirebaseauthService } from './firebaseauth.service';
import { FirestoreService } from './firestore.service';

@Injectable({
  providedIn: 'root'
})
export class CarritoService {

private favorito: Favorito;
favorito$ = new Subject<Favorito>();
path = 'favoritos';
uid = '';
usuario: Usuario;
email = '';


  constructor( public firebaseauthService: FirebaseauthService, public firestoreService: FirestoreService, public router: Router, public alertController: AlertController, public toastController: ToastController) {
    this.firebaseauthService.stateAuth().subscribe( res => {
      console.log(res);
      if (res !== null){
        this.uid = res.uid;
        this.loadUsuario();
     }
    });
   }

  loadFavorite(){
    const path = 'Usuario/' + this.uid + '/' + 'favoritos';
    this.firestoreService.getDoc<Favorito>(path, this.uid).subscribe ( res => {
      console.log(res);
      if(res){
        this.favorito = res;
        this.favorito$.next(this.favorito);
      }else{
        this.initFavorite();
      }
    });
  }

  initFavorite() {
    this.favorito = {
      id: this.uid,
  email: '',

  usuario: this.usuario,
  clientes: [],
  estado: 'enviado',
    };
    this.favorito$.next(this.favorito);
  }

  loadUsuario(){
      const path = 'Usuario';
      this.firestoreService.getDoc<Usuario>(path, this.uid).subscribe( res => {
        this.usuario = res;
        this.loadFavorite();
      })
    }
  

  getFavorite() : Observable<Favorito> {

    return this.favorito$.asObservable();

    /* return new Promise ( resolve => {
      if(!this.uid.length){
        
        resolve(null);
        return;

      }
      if(this.favorito){
        resolve(this.favorito);
        return;
      }else{
        const path = 'Usuario/' + this.uid + '/' + 'favoritos';
    this.firestoreService.getDoc<Favorito>(path, this.uid).subscribe ( res => {
      if(res){
        resolve(res);
        return;
      }else{
        
        resolve(null);
        return;   
      }
    });
     }
    }) */
  } ;

  async addCliente(usuario: Usuario){
    if(this.uid.length) {
      const item = this.favorito.clientes.find(clienteFavorito => {
        return (clienteFavorito.usuario) 
      });
      if(item !== undefined){
        //item.cantidad ++;
      }else{
        const add: ClienteFavorito = {
          cantidad: 1,
          usuario,
        } 
        this.favorito.clientes.push(add);
      }
    }else{

      const alert = await this.alertController.create({
        cssClass: 'body',
        header: 'Necesitas iniciar sesión para guardar tus comercios favoritos',
        message: '¿Te llevamos al login?',
        buttons: [
          {
            text: 'no',
            role: 'cancel',
            cssClass: '',
            handler: (blah) => {
            }
          }, {
            text: 'sí',
            handler: () => {   
              this.router.navigate(['login']);
            }
          }
        ]
      });
      
      await alert.present();
      return;
      }

    
    console.log('en add pedido ->', this.favorito);
    const path = 'Usuario/' + this.uid + '/' + this.path;
    this.firestoreService.createDoc(this.favorito, path, this.uid).then(() => {
      console.log('Añadido con éxito')
    });
  }

  async removeCliente(usuario: Usuario){
    if(this.uid.length) {
      let position = 0;
      const item = this.favorito.clientes.find((clienteFavorito, index) => {
        position = index;
        return (clienteFavorito.usuario.uid === usuario.uid) 
      });
      if(item !== undefined){
        item.cantidad --;
        if (item.cantidad === 0){
          this.favorito.clientes.splice(position, 1);
        }
        console.log('en remove pedido ->', this.favorito);
        const path = 'Usuario/' + this.uid + '/' + this.path;
        this.firestoreService.createDoc(this.favorito, path, this.uid).then(() => {
          console.log('Eliminado con éxito')
        });
      
      }
      }

  }

  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      color: 'light',
    });
    toast.present();
  }

 

  clearFavorite(){

  }



}
