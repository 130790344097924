import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MenuItem } from '../../models';
import { FirestoreService } from '../../services/firestore.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-edit-product-modal',
  templateUrl: './edit-product-modal.component.html',
  styleUrls: ['./edit-product-modal.component.scss']
})
export class EditProductModalComponent implements OnInit {
  @Input() product: MenuItem;
  @Input() uid: string;
  @Output() productUpdated = new EventEmitter<void>();

  productForm: FormGroup;
  isMultiplePrice = false;

  constructor(
    private fb: FormBuilder,
    private firestoreService: FirestoreService,
    private modalController: ModalController
  ) {
    this.productForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      category: ['', Validators.required],
      isMultiplePrice: [false],
      singlePrice: [0, [Validators.required, Validators.min(0)]],
      prices: this.fb.array([]),
      available: [true],
      ingredients: [''],
      calories: [0],
      servingSize: [''],
      tags: [''],
      image: ['']
    });
  }

  ngOnInit() {
    this.loadProductData();
  }

  loadProductData() {
    if (this.product) {
      this.productForm.patchValue(this.product);
      this.isMultiplePrice = this.product.prices.length > 1;
      this.productForm.patchValue({ isMultiplePrice: this.isMultiplePrice });

      if (this.isMultiplePrice) {
        this.product.prices.forEach(price => {
          this.prices.push(this.createPriceGroup(price));
        });
      } else if (this.product.prices.length === 1) {
        this.productForm.patchValue({ singlePrice: this.product.prices[0].amount });
      }
    }
  }

  createPriceGroup(price?: any): FormGroup {
    return this.fb.group({
      size: [price?.size || '', Validators.required],
      amount: [price?.amount || 0, [Validators.required, Validators.min(0)]]
    });
  }

  addPriceGroup() {
    this.prices.push(this.createPriceGroup());
  }

  removePriceGroup(index: number) {
    this.prices.removeAt(index);
  }

  get prices(): FormArray {
    return this.productForm.get('prices') as FormArray;
  }

  togglePriceType() {
    this.isMultiplePrice = !this.isMultiplePrice;
    const priceGroups = this.productForm.get('prices') as FormArray;
    if (this.isMultiplePrice) {
      while (priceGroups.length) {
        priceGroups.removeAt(0);
      }
      priceGroups.push(this.createPriceGroup());
    } else {
      while (priceGroups.length) {
        priceGroups.removeAt(0);
      }
    }
  }

  async onSubmit() {
    if (this.productForm.valid) {
      const formValue = this.productForm.value;
      const updatedProduct: MenuItem = {
        ...this.product,
        name: formValue.name,
        description: formValue.description,
        category: formValue.category,
        available: formValue.available,
        ingredients: formValue.ingredients ? formValue.ingredients.split(',').map((item: string) => item.trim()) : [],
        calories: formValue.calories,
        servingSize: formValue.servingSize,
        tags: formValue.tags ? formValue.tags.split(',').map((item: string) => item.trim()) : [],
        image: formValue.image,
        uid: this.uid,
        prices: this.isMultiplePrice ? formValue.prices : [{ size: 'default', amount: formValue.singlePrice }],
        id: this.product.id
      };

      try {
        await this.firestoreService.updateMenuItem(updatedProduct);
        console.log('Producto actualizado exitosamente');
        this.productUpdated.emit();
        this.modalController.dismiss();
      } catch (error) {
        console.error('Error al actualizar el producto:', error);
      }
    } else {
      console.error('Formulario no es válido');
    }
  }

  closeModal() {
    this.modalController.dismiss();
  }
}
