import { Component, Input } from '@angular/core';
import { ModalController, ToastController, AlertController } from '@ionic/angular';
import { Order } from '../../models';
import { FavoritosService } from '../../services/favoritos.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss'],
})
export class OrderDetailComponent {
  @Input() order: Order;

  constructor(
    private modalController: ModalController,
    private favoritosService: FavoritosService,
    private firestore: AngularFirestore,
    private toastController: ToastController,
    private alertController: AlertController
  ) {}

  dismiss() {
    this.modalController.dismiss();
  }

  async addToFavorites() {
    if (this.favoritosService.hasFavorites()) {
      const toast = await this.toastController.create({
        message: 'Solo puedes tener una orden favorita a la vez. Por favor, elimina la orden actual antes de añadir una nueva.',
        duration: 3000,
        color: 'danger'
      });
      toast.present();
    } else {
      this.favoritosService.addToFavorites(this.order);
      this.modalController.dismiss({ addToFavorites: true });
    }
  }

  async confirmRemoveFromFavorites() {
    const alert = await this.alertController.create({
      header: 'Confirmar eliminación',
      message: '¿Estás seguro de que deseas eliminar este pedido de tus favoritos?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Eliminar',
          handler: () => {
            this.removeFromFavorites();
          }
        }
      ]
    });

    await alert.present();
  }

  removeFromFavorites() {
    this.favoritosService.removeFromFavorites(this.order);
    this.modalController.dismiss({ removeFromFavorites: true });
  }
}
