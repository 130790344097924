import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { Usuario, MenuItem } from "../../models";
import { FirebaseauthService } from "../../services/firebaseauth.service";
import { FirestoreService } from "../../services/firestore.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { CartService } from "../../services/cart.service";  // Asegúrate de importar el servicio de carrito

@Component({
  selector: 'app-order-online',
  templateUrl: './order-online.component.html',
  styleUrls: ['./order-online.component.scss'],
})
export class OrderOnlineComponent implements OnInit, OnDestroy {
  comercioId: string;
  burgers: MenuItem[] = [];
  beverages: MenuItem[] = [];
  postres: MenuItem[] = [];
  bebidas: MenuItem[] = [];
  wraps: MenuItem[] = [];
  paraPicar: MenuItem[] = [];
  menuKids: MenuItem[] = [];
  acai: MenuItem[] = [];
  admin = false;
  user = false;
  defaultImage = 'assets/images/defaultImage.jpg';
  
  usuario: Usuario = {
    uid: "",
    video: "",
    nombre: "",
    responsable: "",
    apellido: "",
    email: "",
    password: "",
    referencia: "",
    foto: "",
    foto1: "",
    qr: "",
    foto2: "",
    latitud: "",
    longitud: "",
    hash: "",
    categoria: "",
    tipo: "",
    telefono: "",
    pageWeb: "",
    direccion: "",
    horario: "",
    suscripcion: "",
    codPostal: "",
    ciudad: "",
    description: "",
    destacado: false,
  };

  selectedSegment = 'menuSegment';
  suscriberUserInfo: Subscription;
  uid = "";

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public firebaseauthService: FirebaseauthService,
    public firestoreService: FirestoreService,
    private cartService: CartService  // Inyectar el servicio de carrito
  ) {
    this.firebaseauthService.stateAuth().subscribe((res) => {
      if (res !== null) {
        this.uid = res.uid;
        this.getUserInfo(this.uid);
      } else {
        console.log("no es cliente");
      }
    });
  }

  async ngOnInit() {
    this.comercioId = this.route.snapshot.paramMap.get('id');
    this.loadProductos();
    const uid = await this.firebaseauthService.getUid();
    console.log(uid);
    /* this.getMenuItems(); */
  }

  ngOnDestroy() {
    if (this.suscriberUserInfo) {
      this.suscriberUserInfo.unsubscribe();
    }
  }

  loadProductos() {
    const path = `menuItems`;
    this.firestoreService.getCollection<MenuItem>(path).subscribe(data => {
      const userItems = data.filter(item => item.uid === this.comercioId);
      this.burgers = userItems.filter(item => item.category === 'burger');
      this.postres = userItems.filter(item => item.category === 'dessert');
      this.beverages = userItems.filter(item => item.category === 'beverage');
      this.wraps = userItems.filter(item => item.category === 'wrap');
      this.paraPicar = userItems.filter(item => item.category === 'snack');
      this.menuKids = userItems.filter(item => item.category === 'kids');
      this.acai = userItems.filter(item => item.category === 'acai');
    });
  }

  getUserInfo(uid: string) {
    const path = "Usuario";
    this.suscriberUserInfo = this.firestoreService
      .getDoc<Usuario>(path, uid)
      .subscribe((res) => {
        if (res) {
          this.usuario = res;
        }
      });
  }

  scrollToSection(sectionId: string) {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  addToCart(item: MenuItem) {
    this.cartService.addToCart(item);
  }
}
