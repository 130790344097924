import { Component, OnInit } from '@angular/core';
import { FirebaseauthService } from '../../services/firebaseauth.service';
import { FirestorageService } from '../../services/firestorage.service';
import { FirestoreService } from '../../services/firestore.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Usuario } from '../../models';
import { Subscription } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Geolocation, Geoposition } from '@ionic-native/geolocation/ngx';


@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
})
export class PerfilComponent implements OnInit {

user = false;

  usuario: Usuario = {
    uid: '',
    nombre: '',
    responsable: '',

    apellido: '',
    email: '',
    password: '',
    referencia: '',
    foto: '',
    foto1: '',
    foto2: '',
    latitud: '',
    longitud: '',
    hash: '',
    qr:'',

    categoria: '',
    tipo: '',
    telefono: '',
    pageWeb: '',
  direccion: '',
  horario: '',
  suscripcion: '',
  codPostal: '',
  ciudad: '',
  description: '',


  };
  
  uid = '';
  
  suscriberUserInfo: Subscription;
  
  ingresarEnable = false;
  
    constructor(public geolocation: Geolocation, public alertController: AlertController, public firebaseauthService: FirebaseauthService, public firestorageService: FirestorageService, public firestoreService: FirestoreService, public auth: AngularFireAuth, public router: Router) {

        this.geolocal();
        this.firebaseauthService.stateAuth().subscribe( res => {
          console.log(res);
          if (res !== null){
            this.uid = res.uid
            this.getUserInfo(this.uid);
          }else{
          this.initUsuario();       
          }
        });
  
     }
  
    async ngOnInit() {
  
      const uid = await this.firebaseauthService.getUid();
      console.log(uid);
  
    }
  
    initUsuario() {
      this.uid = '';
      this.usuario = {
        uid: '',
        nombre: '',
        responsable: '',
        apellido: '',
        email: '',
        password: '',
        referencia: '',
        foto: '',
        foto1: '',
        foto2: '',
        latitud: '',
        longitud: '',
        qr:'',  
        hash: '',
        categoria: '',
        tipo: '',
        pageWeb: '',
        telefono: '',
        direccion: '',
        horario: '',
        suscripcion: '',
        codPostal: '',
        ciudad: '',
        description: '',
      };
  }
  
  
    async registrarse(){
      const credenciales = {
        email: this.usuario.email,
        password: this.usuario.password,
      };
      const res = await this.firebaseauthService.registrar(credenciales.email, credenciales.password).catch(err => {
       console.log('erro -> ', err);
       });
       const uid = await this.firebaseauthService.getUid();
       this.usuario.uid = uid;
       this.guardarUser();
      console.log(uid);
    }
  
  async guardarUser(){
    const path = 'Usuario';
    const name = this.usuario.nombre;  
    this.firestoreService.createDoc(this.usuario, path, this.usuario.uid).then( res=> {
    console.log('guardado con exito')
    
  });  
  }
  
    async salir(){
      this.firebaseauthService.logout();
      this.suscriberUserInfo.unsubscribe();
      
  }
  
  getUserInfo(uid: string){
    const path = 'Usuario';
    this.suscriberUserInfo = this.firestoreService.getDoc<Usuario>(path, this.uid).subscribe( res => {
      this.usuario = res;
      
    })
  }
  
    ingresar(){
      const credenciales = {
        email: this.usuario.email,
        password: this.usuario.password,
      };
      this.firebaseauthService.login(credenciales.email, credenciales.password). then( res => {
        console.log('ingresado con exito')
      });
    }



    async salirPerfil(){
      const alert = await this.alertController.create({
        cssClass: 'body',
        header: '¿ Seguro que quieres salir de Storing ?',
        message: '¡No te pierdas los descuentos y poder encontrar todos los comercios!',
        buttons: [

          {
            text: 'Si',
            handler: () => {
              this.salir().then(() => {
                this.router.navigateByUrl("/home");
              
              }); 
            }
          },

          {
            text: 'No',
            role: 'cancel',
            cssClass: '',
            handler: (blah) => {
              console.log('Confirm Cancel: blah');
            }
          } 
        ]
        
      });
     
      await alert.present();
      
    }

    async geolocal(){
      const obtenerCoordenadas = await this.geolocation.getCurrentPosition();
      this.usuario.latitud = obtenerCoordenadas.coords.latitude;
      this.usuario.longitud = obtenerCoordenadas.coords.longitude;
        console.log(this.usuario.latitud, this.usuario.longitud)
      };


    }
  
   
   
  
  
  
  
 
