import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { FirestoreService } from '../../services/firestore.service';
import { Order, Usuario } from 'src/app/models';
import { FirebaseauthService } from '../../services/firebaseauth.service';

@Component({
  selector: 'app-order-form-modal',
  templateUrl: './order-form-modal.component.html',
  styleUrls: ['./order-form-modal.component.scss']
})
export class OrderFormModalComponent implements OnInit {
  orderForm: FormGroup;
  uid = '';
  id = '';
  
  usuario: Usuario = {
    uid: '',
    video: '',
    nombre: '',
    responsable: '',
    apellido: '',
    email: '',
    password: '',
    referencia: '',
    foto: '',
    foto1: '',
    qr: '',
    foto2: '',
    latitud: '',
    longitud: '',
    hash: '',
    categoria: '',
    tipo: '',
    telefono: '',
    pageWeb: '',
    direccion: '',
    horario: '',
    suscripcion: '',
    codPostal: '',
    ciudad: '',
    description: '',
    destacado: false,
  };

  constructor(
    private fb: FormBuilder,
    private firestoreService: FirestoreService,
    private modalController: ModalController,
    private firebaseauthService: FirebaseauthService
  ) {}

  ngOnInit() {
    this.firebaseauthService.stateAuth().subscribe(user => {
      if (user) {
        this.uid = user.uid;
      }
    });

    this.orderForm = this.fb.group({
      customerName: ['', Validators.required],
      deliveryAddress: ['', Validators.required],
      product: ['', Validators.required],
      price: [0, [Validators.required, Validators.min(0)]]
    });
  }

  onSubmitOrder() {
    if (this.orderForm.valid) {
      const { customerName, deliveryAddress, product, price } = this.orderForm.value;
      const id = this.firestoreService.getId(); // Genera un nuevo ID para la orden
      const newOrder: Order = {
        customerName,
        deliveryAddress,
        product,
        id, // Asegura que el campo id tiene un valor válido
        price,
        latitude: this.usuario.latitud,
        longitude: this.usuario.longitud,
        uid: this.uid
      };
      const path = `orders`; // Se guarda en la raíz de la colección "orders"
      this.firestoreService.createDoc(newOrder, path, id).then(() => {
        console.log('Orden creada exitosamente');
        this.orderForm.reset();
      }).catch((error) => {
        console.error('Error al crear la orden: ', error);
      });
    } else {
      console.error('Formulario no es válido');
    }
  }

  closeModal() {
    this.modalController.dismiss();
  }
}
