import { Component } from '@angular/core';

@Component({
  selector: 'app-payment-plans',
  templateUrl: './payment-plans.component.html',
  styleUrls: ['./payment-plans.component.scss']
})
export class PaymentPlansComponent {
  selectedBasicSubscription: boolean = false;
  selectedPremiumSubscription: boolean = false;

  customAlertOptionsBasic: any = {
    header: 'Select a plan',
    subHeader: 'Basic Subscription',
    message: 'Choose between monthly and yearly plans',
  };

  customAlertOptionsPremium: any = {
    header: 'Select a plan',
    subHeader: 'Premium Subscription',
    message: 'Choose between monthly and yearly plans',
  };

  changeBasicSubscription(event: any) {
    this.selectedBasicSubscription = event.detail.value === 'true';
  }

  changePremiumSubscription(event: any) {
    this.selectedPremiumSubscription = event.detail.value === 'true';
  }
}
