import { Component, OnInit } from '@angular/core';
import { OrderFavoritesService } from '../../services/order-favorites.service';
import { Order } from '../../models';

@Component({
  selector: 'app-order-favorites',
  templateUrl: './order-favorites.component.html',
  styleUrls: ['./order-favorites.component.scss'],
})
export class OrderFavoritesComponent implements OnInit {
  favoriteOrders: Order[] = [];

  constructor(private orderFavoritesService: OrderFavoritesService) {}

  ngOnInit() {
    this.favoriteOrders = this.orderFavoritesService.getFavorites();
    this.orderFavoritesService.favoriteOrders$.subscribe(favorites => {
      this.favoriteOrders = favorites;
    });
  }

  removeFromFavorites(order: Order) {
    this.orderFavoritesService.removeFromFavorites(order);
  }
}
