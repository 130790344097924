import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FirestorageService {

  constructor(public storage: AngularFireStorage) { }

  

uploadImage(file: any, path: string, nombre: string): Promise<string> {
  return new Promise( resolve => {

    const filePath = path + '/' + nombre;
    const ref = this.storage.ref(filePath);
    const task = ref.putString(file, 'data_url');
    task.snapshotChanges().pipe(
      finalize(() => {
        ref.getDownloadURL().subscribe( res => {
          const downloadURL = res;
          resolve(downloadURL);
          return;
        });
      })
   )
  .subscribe();    
});
}


upload(filePath: string, file: File): any {
  return this.storage.upload(filePath, file);
}

ref(filePath: string): any {
  return this.storage.ref(filePath);
}

getDownloadURL(filePath: string): Observable<string> {
  return this.storage.ref(filePath).getDownloadURL();
}
}

