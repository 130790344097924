import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Usuario } from 'src/app/models';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-gestor-comentarios',
  templateUrl: './gestor-comentarios.component.html',
  styleUrls: ['./gestor-comentarios.component.scss'],
})
export class GestorComentariosComponent implements OnInit {

  numComentatios = 2;
  enableMore = true;
  comentarios: Comentario[] = [];  
  comentario = '';
  newComentario: Comentario;
  suscriber: Subscription;
  suscriberUserInfo: Subscription;
  @Input() usuario: Usuario;
  @Input() enable = false;



  constructor(public firestoreService: FirestoreService) { 
     console.log('constrcutor gestor')
  }

  ngOnInit() {
       console.log('gestor comentrios  usuario ->', this.usuario);
       this.loadComentariosUsuarios()
  }

  loadComentariosUsuarios() {
    let startAt = null;
    
    if (this.comentarios.length) {
      startAt = this.comentarios[ this.comentarios.length - 1].fecha;
    }
   const path = 'Usuario/' + this.usuario.uid + '/comentarios';
   this.suscriber = this.firestoreService.getCollectionPaginada<Comentario>(path, this.numComentatios, startAt ).subscribe( res => {
     if (res.length < this.numComentatios ) {
         this.enableMore = false;
     }
     if(res.length) {
       res.forEach( comentario => {
         comentario.respuestaEnable = false;
         let exist = this.comentarios.find( (commentExist, index) => {
           if ( commentExist.id === comentario.id) {
                   this.comentarios[index] = comentario;
           }
           return commentExist.id === comentario.id
         });
         if (exist === undefined) {    
         this.comentarios.push(comentario);
         }
       });
       console.log(res);
     } 
   });
  }

  responder(comentario: Comentario) {

    if (this.enable) {
      const respuesta = this.comentario;
      const path = 'Usuario/' + this.usuario.uid + '/comentarios';
      comentario.respuestaEnable = false;
      const updateDoc = {
        respuesta
      }
      this.firestoreService.updateDoc(updateDoc, path, comentario.id);
     }

     else  {  
         console.log('no se  puede responder ');
    }
  }


}

interface Comentario {
  autor: any;
  comentario: string;
  fecha: any;
  id: string;
  foto: any;
  valor: any;
  img: any;
  respuesta: string;
  respuestaEnable: boolean;
 }
