import { Component, OnInit } from '@angular/core';
import { FirebaseauthService } from '../../services/firebaseauth.service';
import { Usuario } from '../../models';
import { Platform } from '@ionic/angular';
import { Location } from '@angular/common';
import { Router } from '@angular/router';


@Component({
  selector: 'app-options-login',
  templateUrl: './options-login.component.html',
  styleUrls: ['./options-login.component.scss'],
})
export class OptionsLoginComponent implements OnInit {
  aceptarPolitica: boolean = false; 

  datosUsuario: Usuario;

  usuario: Usuario = {
    uid: '',
    nombre: '',
    responsable: '',
  
    apellido: '',
    email: '',
    password: '',
    referencia: '',
    foto: '',
      foto1: '',
      foto2: '',
    latitud: '',
    longitud: '',
    hash: '',
    qr:'',
  
    categoria: '',
    tipo: '',
  
    telefono: '',
    pageWeb: '',
    direccion: '',
    horario: '',
    suscripcion: '',
    codPostal: '',
    ciudad: '',
    description: '',
  
  };
  

  isIos = false;
  loginEmail = false;

  constructor( private router: Router, private location: Location, private firebaseauthService: FirebaseauthService,
              private platform: Platform) {
                    this.isIos = this.platform.is('ios');
              }

  ngOnInit() {
    this.initializeRotatingImage();
  }
  checkPolicy() {
    // Aquí puedes manejar cualquier lógica adicional cuando se cambie el estado del checkbox
    console.log('Checkbox policy changed:', this.aceptarPolitica);
  }

  goBackToLogin() {
    this.location.back();
  }
  goBack() {
    this.loginEmail = false; // Navega a la página anterior
  }
  ingresar(){
    const credenciales = {
      email: this.usuario.email,
      password: this.usuario.password,
    };
    this.firebaseauthService.login(credenciales.email, credenciales.password).then( res => {
      console.log('ingresado con exito')
      this.router.navigateByUrl("/home");      
    })
  }
  
  loginGoogle() {
    this.firebaseauthService.loginWithGoogle();
  }
  
  loginFacebook () {
    this.firebaseauthService.loginWithFacebook();
  }

  loginApple() {
      console.log('loginApple()');
      this.firebaseauthService.signInWithApple();
  }

  initializeRotatingImage() {
    const rotatingImage = document.getElementById('rotatingImage');

    if (rotatingImage) {
      // Establecer la duración de la animación inicial
      let animationDuration = 10; // 10 segundos

      // Aplicar la duración de la animación
      rotatingImage.style.animationDuration = `${animationDuration}s`;

      // Event listener para cambiar la velocidad al pasar el dedo sobre la imagen
      rotatingImage.addEventListener('touchmove', (event: TouchEvent) => {
        // Calcular la nueva velocidad basado en el movimiento del dedo
        const touchSpeed = Math.max(1, Math.min(30, 30 / (event.touches[0].clientY || 1)));
        
        // Actualizar la duración de la animación
        rotatingImage.style.animationDuration = `${touchSpeed}s`;
      });

      // Event listener para restaurar la velocidad cuando se quita el dedo
      rotatingImage.addEventListener('touchend', () => {
        rotatingImage.style.animationDuration = `${animationDuration}s`;
      });
    }



  }
}
